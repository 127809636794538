<template>
	<div class="order_detail_wrap">
		<div class="order-title">
			<div class="back_img">
				<img class="back_img"
					src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/normal_icon/Back%403x.png"
					@click="back_list" alt />
			</div>
			订单详情
		</div>

		<div class="container" v-if="detail">

			<!-- 商品信息 -->
			<div class="order_goods_container">
				<div class="order_goods_orderid">
					<div class="order_sn">
						<div v-if="detail.order_status == 1||detail.order_status == 15">通告编号：{{detail.task_sn}}</div>
						<div v-else>订单编号：{{detail.order_sn}}</div>
						<span class="iconfont icon-fuzhi1 cuIcon-copy"
							@click="copyContent(detail.order_status == 1||detail.order_status == 15 ? detail.task_sn : detail.order_sn)"></span>
					</div>
					<div style="color:#FF6685" v-if="orderStatus[detail.order_status]">
						{{orderStatus[detail.order_status]}}
					</div>
				</div>

				<div class="order_goods_descontainer" @click="to_goods_detail">
					<img class="order_goods_icon" v-lazy="detail.goods_pic" />
					<div class="order_goods_descontent">
						<div>
							<div class="goods-name">{{detail.goods_name}}</div>
							<task-tag :task_require="detail.task_require" :task_require_sub="detail.task_require_sub"
								:task_type="detail.task_type" :xhs_prom_type="detail.xhs_prom_type">
							</task-tag>
						</div>

						<div>
							<div class="countdown-time" v-if="detail.countdown_time">
								<div>{{detail.countdown_time}}</div>
								<div v-if="detail.order_status==5">（承诺{{detail.promise_commit_time}}h）</div>
								<!--                <text v-if="detail.order_status==8" class="cuIcon-question"-->
								<!--                      data-name="returnTipModel" @click="showModel" style="margin-left: 5px;"></text>-->
							</div>

							<div class="order_goods_price_wrap">
								<div class="order_goods_pricediv">
									<div class="order_goods_pricelable">佣金</div>
									<div class="order_goods_price" style="margin-left:10px">{{detail.mt_price / 100}}
									</div>
									<div class="order_goods_pricelable" style="margin-left:10px">元</div>
								</div>
								<div class="connect-btn"
									v-if="detail.order_status!=1&&detail.order_status!=11&&detail.order_status!=12&&detail.order_status!=13&&detail.order_status!=14"
									@click.stop="toChat">
									<van-icon name="chat-o" />
									联系商家
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<template v-if="detail.order_status==1||detail.order_status==15">
				<div class="goods_request_container">
					<div class="goods_request_content">
						<div class="goods_request_titlediv">
							<div class="goods_request_titleline"></div>
							<div class="goods_request_title">通告要求</div>
						</div>
						<div class="goods_request_detail">
							<span>{{taskDetail && taskDetail.demand ? taskDetail.demand : '无'}}
							</span>
						</div>
					</div>
				</div>

				<!-- 达人要求 -->
				<div class="goods_request_container" v-if="taskDetail && taskDetail.task_require==4">
					<div class="goods_request_content">
						<div class="goods_request_titlediv">
							<div class="goods_request_titleline"></div>
							<div class="goods_request_title">达人要求</div>
						</div>
						<div class="goods_request_item" v-if="taskDetail.fans_num && taskDetail.fans_num!=0">
							<div class="goods_request_itemtitle">粉丝要求</div>
							<div class="goods_request_itemdes">≥{{taskDetail.fans_num}}</div>
						</div>
						<div class="goods_request_item">
							<div class="goods_request_itemtitle">性别要求</div>
							<div class="goods_request_itemdes">{{sexType[taskDetail.sex]}}</div>
						</div>
						<div class="goods_request_item" v-if="taskDetail.tag_ids">
							<div class="goods_request_itemtitle">内容标签</div>
							<div class="goods_request_itemdes">
								<div class="tag-con">
									<div class="tag-item" v-for="(item,index) in field_show_arr" :key="index">{{item}}
									</div>
									<div class="tag-item more-tag" v-if="fieldShow" @click="showAllFieldTag">
										<div>展开</div>
										<span class="iconfont cuIcon-unfold"></span>
									</div>
								</div>
							</div>
						</div>
						<div class="goods_request_item" v-if="taskDetail.spec_ids">
							<div class="goods_request_itemtitle">特色标签</div>
							<div class="goods_request_itemdes">
								<div class="tag-con">
									<div class="tag-item" v-for="(item, index) in tag_show_arr" :key="index">{{item}}
									</div>
									<div class="tag-item more-tag" v-if="tagShow" @click="showAllSpecTag">
										<div>展开</div>
										<span class="icon cuIcon-unfold"></span>
									</div>
								</div>
							</div>
						</div>
						<div class="goods_request_item" v-if="taskDetail.budget && taskDetail.budget != 0">
							<div class="goods_request_itemtitle">最高预算</div>
							<div class="goods_request_itemdes">{{taskDetail.budget}}元</div>
						</div>
					</div>
				</div>

				<!-- 模特要求 -->
				<div class="goods_request_container" v-else>
					<div class="goods_request_content" v-if="taskDetail">
						<div class="goods_request_titlediv">
							<div class="goods_request_titleline"></div>
							<div class="goods_request_title">模特要求</div>
						</div>
						<div class="goods_request_item">
							<div class="goods_request_itemtitle">年龄要求</div>
							<div v-if="taskDetail.min_age == 0 && taskDetail.max_age == 0"
								class="goods_request_itemdes">不限</div>
							<div v-else class="goods_request_itemdes">
								最小{{taskDetail.min_age}}岁，最大{{taskDetail.max_age}}岁</div>
						</div>
						<div class="goods_request_item">
							<div class="goods_request_itemtitle">模特类型</div>
							<div class="goods_request_itemdes">{{mdTypes[taskDetail.mote_type]}}</div>
						</div>
						<div class="goods_request_item" v-if="taskDetail.budget&&taskDetail.budget!=0">
							<div class="goods_request_itemtitle">最高预算</div>
							<div class="goods_request_itemdes">{{taskDetail.budget}}元</div>
						</div>
					</div>
				</div>

				<!-- 拍摄要求 -->
				<div class="goods_request_container">
					<div class="goods_request_content" v-if="taskDetail">
						<div class="goods_request_titlediv">
							<div class="goods_request_titleline"></div>
							<div class="goods_request_title">拍摄要求</div>
						</div>
						<div class="goods_request_item" v-if="taskDetail.task_require==4">
							<div class="goods_request_itemtitle">拍摄需求</div>
							<div class="goods_request_itemdes">{{promType[taskDetail.xhs_prom_type]}}</div>
						</div>
						<div class="goods_request_item" v-if="taskDetail.xhs_prom_type!=3">
							<div class="goods_request_itemtitle">露脸要求</div>
							<div class="goods_request_itemdes">{{taskDetail.show_face == 1 ? '必须' : '随意'}}</div>
						</div>
						<div class="goods_request_item"
							v-if="taskDetail.photo_num&&(taskDetail.xhs_prom_type==1||taskDetail.task_require==1||taskDetail.task_require==3)">
							<div class="goods_request_itemtitle">照片数量</div>
							<div class="goods_request_itemdes">不少于{{taskDetail.photo_num}}张</div>
						</div>
						<div class="goods_request_item"
							v-if="(taskDetail.video_check==1&&taskDetail.video_len)||(taskDetail.task_require==4&&taskDetail.xhs_prom_type==2&&taskDetail.video_len)">
							<div class="goods_request_itemtitle">视频要求</div>
							<div class="goods_request_itemdes">不低于{{taskDetail.video_len}}秒</div>
						</div>
						<div class="goods_request_item" v-if="taskDetail.task_require != 4">
							<div class="goods_request_itemtitle">拍摄要求</div>
							<div class="goods_request_itemdes">
								<span>{{taskDetail.photo_demand ? taskDetail.photo_demand : '无要求'}}
								</span>
							</div>
						</div>
						<div class="goods_request_item">
							<div class="goods_request_itemtitle">
								{{(taskDetail.task_require==2||taskDetail.task_require==4)?'交付时间':'出图时间'}}
							</div>
							<div class="goods_request_itemdes">
								收到货
								<span
									style="color: #FF6685;font-weight: 700;padding: 0 3px;">{{taskDetail.commit_over_time}}天</span>
								内
							</div>
						</div>
						<div class="goods_request_item" v-if="taskDetail.task_require==4&&taskDetail.jbyq">
							<div class="goods_request_itemtitle">脚本来源</div>
							<div class="goods_request_itemdes">{{scriptType[taskDetail.jbyq]}}</div>
						</div>
						<div class="goods_request_item" v-if="taskDetail.task_require==4">
							<div class="goods_request_itemtitle">留存时间</div>
							<div class="goods_request_itemdes">
								<div class="flex">
									<span style="font-weight: 700;color: #FF6685;margin-right: 8px;">
										{{taskDetail.xhs_forever_save==1?'永久':(taskDetail.xhs_save_time+'个月')}}
									</span>
									<div style="position: relative;">
										<van-icon name="question-o" class="cuIcon-question" @click="showTip" />
										<template v-if="saveTimeTip">
											<div class="sanjiao"></div>
											<div class="tip-pop" @click="showTip">小红书笔记发布后，留存时间内不可删除，否则以违规处理</div>
										</template>
									</div>
								</div>
							</div>
						</div>
					</div>


					<!-- 参考图 -->

					<div class="goods_request_content" v-if="taskDetail && taskDetail.photo_sample"
						style="margin-top: 20px;">
						<div class="goods_request_titlediv">
							<div class="goods_request_titleline"></div>
							<div class="goods_request_title">参考图</div>
						</div>
						<div class="goods_model_imgcontainer">
							<div class="goods_model_imgdiv" :key="index"
								v-for="(item,index) in taskDetail.photo_sample">
								<img class="goods_model_img" @click="show_image(index)" :src="item" />
							</div>
						</div>
					</div>


					<!-- 参考视频 -->

					<div class="goods_request_content" v-if="taskDetail && taskDetail.video_sample"
						style="margin-top: 20px;">
						<div class="goods_request_titlediv">
							<div class="goods_request_titleline"></div>
							<div class="goods_request_title">参考视频</div>
						</div>
						<div class="goods_model_imgcontainer">
							<div class="goods_model_imgdiv" :key="index"
								v-for="(item,index) in taskDetail.video_sample">
								<video v-if="item" :src="item" @click="show_video(taskDetail.video_sample)"></video>
								<img class="play-icon" src="../../../public/static/play.png" />
							</div>
						</div>
					</div>
				</div>

				<!-- 通告信息 -->
				<div class="goods_request_container">
					<div class="goods_request_content" v-if="taskDetail">
						<div class="goods_request_titlediv">
							<div class="goods_request_titleline"></div>
							<div class="goods_request_title">通告信息</div>
						</div>
						<template v-if="taskDetail.task_require==4&&taskDetail.xhs_prom_type==3">
							<div class="goods_request_item">
								<div class="goods_request_itemtitle">联系方式</div>
								<div class="goods_request_itemdes">{{taskDetail.connect_type==1?"微信联系":"平台联系"}}</div>
							</div>
						</template>
						<template v-else>
							<div class="goods_request_item" v-if="taskDetail.task_type == 2">
								<div class="goods_request_itemtitle">商品重量</div>
								<div class="goods_request_itemdes">
									{{taskDetail.goods_weight?taskDetail.goods_weight:0}}kg
								</div>
							</div>
							<div class="goods_request_item">
								<div class="goods_request_itemtitle">联系方式</div>
								<div class="goods_request_itemdes">{{taskDetail.connect_type==1?"微信联系":"平台联系"}}</div>
							</div>
							<div class="goods_request_item">
								<div class="goods_request_itemtitle">不发地区</div>
								<div class="goods_request_itemdes">{{taskDetail.no_cityArr}}</div>
							</div>
							<div class="goods_request_item" v-if="taskDetail.task_type == 2">
								<div class="goods_request_itemtitle">寄回地址</div>
								<div class="goods_request_itemdes">{{taskDetail.return_address}}</div>
							</div>
							<div class="goods_request_item"
								v-if="taskDetail.publish_channel&&taskDetail.publish_channel!=0">
								<div class="goods_request_itemtitle">发布渠道</div>
								<div class="goods_request_itemdes">
									<div class="flex align-center">
										<img class="channel-icon" :src="channels[taskDetail.publish_channel].icon" />
										<div>{{channels[taskDetail.publish_channel].name}}</div>
									</div>
								</div>
							</div>
						</template>
					</div>
				</div>
			</template>

			<!-- 商家微信 -->
			<div class="order_wx_container" v-if="detail.showWechat">
				<div class="order_wx_leftdiv">
					<div class="order_wx_title">商家微信 : {{detail.wechat.substring(0, 1) + '****' +
            detail.wechat.substring(detail.wechat.length - 1)}}
					</div>
					<div class="order_wx_subtitle">与商家沟通更详细的下单细节</div>
				</div>
				<div class="order_wx_copy" @click="copyContent(detail.wechat)">复制</div>
			</div>

			<div class="dispute_container" v-if="detail.is_dispute==1">
				<div class="dispute-title">
					<div class="title_main">商家投诉</div>
					<div class="dispute_state">纠纷状态：
						<span style="color: #FF6666;">{{detail.state==1?'纠纷中':'已撤销'}}</span>
					</div>
				</div>
				<div class="dispute-reason">
					<div>{{detail.dispute_reason}}</div>
					<div class="dispute_time">{{detail.dispute_time}}</div>
				</div>
			</div>

			<!-- 快递 寄出  -->
			<div v-if="detail.seller_express && detail.seller_express.express_info" class="order_logistics_container">
				<div class="order_logistics_titlediv">
					<div class="order_logistics_typediv">
						<div class="order_logistics_typename">{{detail.seller_express.express_company}}</div>
						<div class="order_logistics_typenum">{{detail.seller_express.express_no}}</div>
						<div class="change_btn" data-id="detail.seller_express_id" data-type="2"
							v-if="detail.seller_express_id" @click="show_change(2)">修改
						</div>
					</div>
					<div class="order_logistics_statusdiv">商家寄出</div>
				</div>

				<div class="order_logistics_table">
					<div class="order_logistics_tablecell" :key="index"
						v-for="(item,index) in detail.seller_express.express_info.result.list"
						v-show="!(index > 0 && !is_show_jc)">
						<div class="order_logistics_cellleft">
							<div class="order_logistics_timediv">
								<div class="order_logistics_time">
									{{item.time.slice(item.time.indexOf('-') + 1, item.time.indexOf(' '))}}
								</div>
								<div class="order_logistics_subtime">
									{{item.time.slice(item.time.indexOf(' ') + 1, item.time.lastIndexOf(':'))}}
								</div>
							</div>

							<div class="order_logistics_linediv">
								<div class="order_logistics_dian"
									:style="{backgroundColor:index == 0 ? detail.seller_express.express_info.result.issign ? '#66E038' : '#FF6666' : '#C4C4CC' , width: index == 0 ? '20px' : '10px', height:index == 0 ? '20px' : '10px',marginTop: index == 0 ? '5px' : '8px'}">
								</div>
								<div class="order_logistics_line"
									v-show="!(index > 0 && index == detail.seller_express.express_info.result.list.length - 1)">
								</div>
							</div>

						</div>
						<div class="order_logistics_cellright">
							<div v-if="!detail.seller_express.express_info.result.issign && index == 0"
								class="order_logistics_status" :style="{color: index == 0 ? '#4DA6FF' : '#8A8E99'}">运输中
							</div>
							<div class="order_logistics_status" :style="{color:index == 0 ? '#4DA6FF' : '#8A8E99'}">已完成
							</div>
							<div class="order_logistics_des" :style="{color: index == 0 ? '#262A33' : '#8A8E99'}">
								{{item.status}}
							</div>
						</div>
					</div>
				</div>

				<div v-if="detail.seller_express.express_info.result.list.length > 1" class="order_flow_bottom">
					<div class="order_message_morediv" v-show="!is_show_jc" @click="order_logistics_more(2)"
						style="margin-top:20px">
						<div class="order_message_moretitle">点击查看更多</div>
						<img class="order_message_moreicon"
							src="https://img.jimeimadou.com/common/resource/normal_icon/order_xia_icon.png" />
					</div>
				</div>
			</div>

			<!-- 图片审核 -->
			<div class="upload_task_wrap" v-if="detail.order_status == 5">
				<div class="upload_task" v-if="detail.task_require==4" @click="showModel('xhs')">上传笔记</div>
				<div class="upload_task" v-else @click="upload_baogao">上传报告</div>
			</div>

			<div v-if="detail.baogao_info && (detail.baogao_info.state == 1||detail.baogao_info.state == 2||detail.baogao_info.state == 3)"
				class="order_imagdiv_container">
				<div class="order_imagdiv_itemcontainer">
					<div class="audit-status-con">
						<div class="flex">
							<template v-if="detail.baogao_info.state == 1">
								<van-icon name="info" />
								<div>待审核</div>
							</template>
							<template v-else-if="detail.baogao_info.state == 2">
								<van-icon name="checked" />
								<div>审核通过</div>
							</template>
							<template v-else-if="detail.baogao_info.state == 3 || detail.order_status==7">
								<van-icon name="clear" />
								<div>审核未通过</div>
							</template>
						</div>
						<template
							v-if="detail.baogao_info.state == 1||detail.baogao_info.state == 3||detail.order_status==7">
							<div class="re_baogao" v-if="detail.task_require==4" @click="showModel('xhs')">重新提交</div>
							<div class="re_baogao" v-else @click="upload_baogao">重新提交</div>
						</template>
					</div>

					<div v-if="detail.baogao_info.state == 3" class="order_imagdiv_itemtitle">
						拒绝原因：{{detail.baogao_info.refuse_reason}}
					</div>

					<!-- 展示报告照片和视频/笔记链接 -->
					<div v-if="detail.task_require==4" class="baogao-link" @click="toWebdiv">
						<div v-if="detail.baogao_info.xhs_note_url" class="url"
							@click="copyContent(detail.baogao_info.xhs_note_url,'url')">
							{{detail.baogao_info.xhs_note_url}}
						</div>
						<div v-if="detail.baogao_info.xhs_note_str" class="link"
							@click="copyContent(detail.baogao_info.xhs_note_str, 'link')">
							{{detail.baogao_info.xhs_note_str}}
						</div>
					</div>
					<div v-else class="order_imagdiv_content">
						<div class="order_imagdiv_item" data-flag="video" data-index="index"
							v-for="(item,index) in detail.baogao_info.videos" :key="index" @click="show_video(item)">
							<video :src="item" class="video_item" data-src="item">
							</video>
							<img class="play-icon" src="../../../public/static/play.png" />
						</div>
						<div class="order_imagdiv_item" @click="show_baogao(index)" data-flag="image" data-index="index"
							:key="index" v-for="(item,index) in detail.baogao_info.photos">
							<img class="order_imagdiv" :src="item" />
						</div>
					</div>
					<div v-if="detail.baogao_info && detail.baogao_info.update_time" class="order_imagdiv_time">
						{{detail.baogao_info.update_time}}
					</div>
				</div>
			</div>

			<!-- 寄回地址 -->
			<div class="goods_model_content" style="margin-top:20px;border-radius: 0;" v-if="detail.order_status == 8">
				<div class="goods_request_titlediv" style="margin-bottom:10px">
					<div class="goods_request_titleline"></div>
					<div class="goods_request_title">寄回地址</div>
				</div>
				<div class="order_wx_container" style="margin:0;padding:0">
					<div class="order_wx_leftdiv">
						<div class="order_wx_title">{{detail.seller_receive_addr.receive_name}} :
							{{detail.seller_receive_addr.receive_phone}}
						</div>
						<div class="order_wx_subtitle">{{detail.seller_receive_addr.receive_detail}}</div>
					</div>
					<div class="order_wx_copy"
						@click="copyContent(detail.seller_receive_addr.receive_name+' '+detail.seller_receive_addr.receive_phone+' '+detail.seller_receive_addr.receive_detail)">
						复制
					</div>
				</div>
			</div>

			<!-- 快递 寄回 -->
			<div v-if="detail.mt_express && detail.mt_express.mt_express_info" class="order_logistics_container">
				<div class="order_logistics_titlediv">
					<div class="order_logistics_typediv">
						<div class="order_logistics_typename">{{detail.mt_express.mt_express_company}}</div>
						<div class="order_logistics_typenum">{{detail.mt_express.mt_express_no}}</div>
						<div class="change_btn" data-id="detail.mt_express_id" data-type="1" v-if="detail.mt_express_id"
							@click="show_change(1)">修改
						</div>
					</div>
					<div class="order_logistics_statusdiv">模特寄出</div>
				</div>

				<div class="order_logistics_table">
					<div class="order_logistics_tablecell" :key="index"
						v-for="(item,index) in detail.mt_express.mt_express_info.result.list"
						v-show="!(index > 0 && !is_show_jh)">
						<div class="order_logistics_cellleft">
							<div class="order_logistics_timediv">
								<div class="order_logistics_time">{{item.time.slice(item.time.indexOf('-') + 1, item.time.indexOf('
                  '))}}
								</div>
								<div class="order_logistics_subtime">{{item.time.slice(item.time.indexOf(' ') + 1,item.time.lastIndexOf(':'))}}</div>
							</div>
							<div class="order_logistics_linediv">
								<div class="order_logistics_dian"
									:style="{backgroundColor: index == 0 ? detail.mt_express.mt_express_info.result.issign ? '#66E038' : '#FF6666' : '#C4C4CC',width: index == 0 ? '20px' : '10px', height: index == 0 ? '20px' : '10px',marginTop: index == 0 ? '5px' : '8px'}">
								</div>
								<div class="order_logistics_line"
									v-show="!(index > 0 && index == detail.mt_express.mt_express_info.result.list.length - 1)">
								</div>
							</div>

						</div>
						<div class="order_logistics_cellright">
							<div v-if="!detail.mt_express.mt_express_info.result.issign && index == 0"
								class="order_logistics_status" :style="{color: index == 0 ? '#4DA6FF' : '#8A8E99'}">运输中
							</div>
							<div class="order_logistics_status" :style="{color: index == 0 ? '#4DA6FF' : '#8A8E99'}">
								已完成
							</div>
							<div class="order_logistics_des" :style="{color: index == 0 ? '#262A33' : '#8A8E99'}">
								{{item.status}}
							</div>
						</div>
					</div>
				</div>

				<div v-if="detail.mt_express.mt_express_info.result.list.length > 1" class="order_flow_bottom">
					<div class="order_message_morediv" v-show="!is_show_jh" @click="order_logistics_more(1)"
						style="margin-top:20px">
						<div class="order_message_moretitle">点击查看更多</div>
						<img class="order_message_moreicon"
							src="https://img.jimeimadou.com/common/resource/normal_icon/order_xia_icon.png" />
					</div>
				</div>
			</div>

			<!-- 留言 -->
			<div class="order_message_container">
				<div class="order_message_inputcontainer">
					<div class="order_message_inputleft">
						<van-field class="order_message_input" v-model="message" rows="2" autosize type="textarea"
							placeholder="请输入你想给商家的留言" />
					</div>
					<div class="order_message_inputbtn" @click="upload_msg">留言</div>
				</div>

				<div class="order_message_table">
					<div class="order_message_tablecell" :key="index" v-for="(item,index) in detail.order_msg_list"
						v-show="!(index > 1 && !is_show_usermsg)">
						<img v-if="item.type == 2" class="order_message_usericon" :src="detail.head_ico" />
						<img v-if="item.type == 1" :src="detail.user_head" class="order_message_usericon" />
						<div class="order_message_rightdiv">
							<div class="order_message_namediv">
								<div v-if="item.type == 2" class="order_message_shopdiv">{{detail.seller_nick}}
								</div>
								<div v-if="item.type == 1" class="order_message_shopdiv">{{detail.user_nick}}
								</div>
								<div class="order_message_time">{{item.add_time}}</div>
							</div>
							<div class="order_message_des">{{item.content}}</div>
						</div>
					</div>
				</div>

				<div v-show="detail.order_msg_list && detail.order_msg_list.length > 2 && !is_show_usermsg"
					class="order_message_morediv" @click="order_logistics_more(0)">
					<div class="order_message_moretitle">点击查看更多</div>
					<img class="order_message_moreicon"
						src="https://img.jimeimadou.com/common/resource/normal_icon/order_xia_icon.png" />
				</div>
			</div>

			<!-- 流程 -->
			<div class="order_flow_container" v-if="detail && detail.order_flow_list && detail.order_flow_list.length">
				<div class="order_flow_table">
					<div class="order_flow_tablecell" :key="index" v-for="(item,index) in detail.order_flow_list"
						v-show="!(index > 2 && !is_show_flow)">
						<div class="order_flow_left">
							<img v-if="index == 0 && (item.order_status == 2 || item.order_status == 7)"
								src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/order_icon/order_cha_icon.png"
								class="order_flow_img" />
							<img v-if="index == 0 && (item.order_status != 2 && item.order_status != 7)"
								src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/order_icon/order_gou_icon.png"
								class="order_flow_img" />
							<img v-if="index != 0"
								src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/order_icon/order_gou_icon.png"
								class="order_flow_img" />
							<div class="order_flow_line" v-show="!(index == detail.order_flow_list.length - 1)"></div>
						</div>
						<div class="order_flow_right">
							<div class="order_flow_des">{{item.remark}}</div>
							<div class="flex">
								<div class="order_flow_time">{{item.add_time}}</div>
								<template v-if="detail.order_status==18 && index==0 && detail.is_dispute!=1">
									<div class="chat-btn" @click="showModel" style="display: none">申请取消</div>
									<div class="chat-btn" @click="showModel" style="display: none">确认沟通</div>
								</template>
								<template v-if="detail.order_status==3 && index==0 && detail.is_dispute!=1">
									<div class="chat-btn" @click="showModel" style="display: none">申请取消</div>
								</template>
							</div>
						</div>
					</div>
				</div>

				<div class="order_flow_bottom" v-show="detail.order_flow_list.length > 3 && !is_show_flow"
					style="background-color:white">
					<div class="order_message_morediv" @click="order_logistics_more(3)" style="margin-top:0">
						<div class="order_message_moretitle">点击查看更多</div>
						<img class="order_message_moreicon"
							src="https://img.jimeimadou.com/common/resource/normal_icon/order_xia_icon.png" />
					</div>
				</div>
			</div>

			<div v-if="detail.order_status == 8 && detail.task_type == 2" class="order_upload_div"
				style="display: none">
				<div class="order_upload_btn">1.添加商家</div>
				<div class="order_upload_btn" @click="show_bottom">2.上传发货单号</div>
			</div>

		</div>

		<van-loading color="#1989fa" type="spinner" v-else />

		<van-dialog v-model="showXhsNote" title="提交笔记" show-cancel-button :before-close="onBeforeClose"
			@confirm="commitNoteLink">
			<div class="xhs_tip" @click="toCourse">
				<van-icon name="question-o" class="cuIcon-question" />如何获取笔记链接
			</div>
			<van-field class="reason-input xhs" v-model="note_link" rows="3" autosize type="textarea"
				placeholder="请输入已发布笔记链接" />
			<div class="reason-tip">
				<div class="text-red">未经商家允许，不许在笔记保留时间内删除
					（{{detail && detail.xhs_forever_save == 1 ? '永久' : detail && detail.xhs_save_time + '个月'}}） ，否则将视为违规
				</div>
			</div>
		</van-dialog>

		<van-popup v-model="showExpress" round position="bottom">
			<div class="title">填写物流信息</div>
			<div class="report-tip">
				请填写商家发货给你的物流单号！收到货后请在48小时内拍好并反馈给商家！超时有可能会被限制报名！
			</div>
			<van-field class="reason-input" v-model="expressno" placeholder="请填写物流单号" />
			<div class="report-tip">
				选择快递公司
			</div>
			<div class="company-container">
				<div class="company-item-con" v-for="(item, index) in expressList" :key="index">
					<div :class="['company-item', current == index ? 'select_company' : '']"
						@click="chooseCompany(index)">
						{{item.kd_company}}
					</div>
				</div>
			</div>
			<van-button round type="primary" @click="expressSave">确认</van-button>
		</van-popup>

		<van-popup v-model="showXhsUrl" position="right" :style="{ width: '90%' }">
			<img :src="xhs_url" style="width: 100%" />
		</van-popup>

		<!--    <transition enter-active-class="slide_in" leave-active-class="slide_out">-->
		<!--      <router-view class="commit_baogao_detail"></router-view>-->
		<!--    </transition>-->
	</div>
</template>

<script>
	import taskTag from "../../components/task-tag";
	import orderDetailDataMixin from "./mixin/orderDetailDataMixin"

	export default {
		name: "OrderDetail",
		components: {
			taskTag
		},
		mixins: [orderDetailDataMixin],
	}
</script>

<style lang="scss" scoped>
	.order_detail_wrap {
		height: 100vh;
		position: relative;

		.van-loading {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateX(-30px) translateY(-30px);

			.van-loading__spinner {
				width: 60px;
				height: 60px;
			}
		}

		.order-title {
			width: 100%;
			height: 80px;
			display: flex;
			font-size: 35px;
			align-items: center;
			justify-content: center;
			position: relative;
			padding: 20px;
			background: #fff;
			box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.05);

			.back_img {
				position: absolute;
				left: 20px;
				width: 50px;
				height: 50px;
				background-color: rgba(220, 220, 220, 0.5);
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					height: 80%;
					width: 80%;
					margin-left: -10px;
					opacity: 0.6;
				}
			}
		}

		.container {
			height: 94vh;
			overflow-y: auto;

			.order_goods_container {
				width: 100%;
				padding: 20px;
				background-color: white;
				font-size: 28px;

				.order_goods_orderid {
					color: #262A33;
					line-height: 40px;
					display: flex;
					justify-content: space-between;

					.order_sn {
						display: flex;

						.iconfont {
							font-size: 26px;
							margin-left: 8px;
						}
					}
				}

				.order_goods_descontainer {
					display: flex;
					margin-top: 20px;

					.order_goods_icon {
						width: 200px;
						height: 200px;
						border-radius: 10px;
						flex-shrink: 0;
						overflow: hidden;
						object-fit: cover;
					}

					.order_goods_descontent {
						width: 100%;
						padding-left: 20px;
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						overflow: hidden;

						.goods-name {
							line-height: 34px;
						}

						.countdown-time {
							font-size: 24px;
							color: #FF6685;
							display: flex;
							line-height: 40px;
						}

						.order_goods_price_wrap {
							display: flex;
							justify-content: space-between;

							.order_goods_pricediv {
								display: flex;
							}

							.order_goods_pricelable {
								height: 34px;
								font-size: 22px;
								font-weight: 400;
								color: #262A33;
								line-height: 34px;
							}

							.order_goods_price {
								height: 42px;
								font-size: 36px;
								font-weight: 600;
								color: #FF704D;
								line-height: 34px;
							}

							.connect-btn {
								line-height: 36px;
								border-radius: 20px;
								padding: 0 10px;
								border: 1px solid #FF6685;
								color: #FF6685;
								display: flex;
								align-items: center;
								font-size: 22px;
								margin-left: 15px;

								.van-icon {
									font-size: 26px;
									color: #FF6685;
									margin-right: 5px;
								}
							}
						}

						::v-deep .task-tag-con {
							margin-top: 6px;

							.type-tip {
								font-size: 24px;
							}
						}
					}
				}
			}

			.goods_request_container {
				width: 100%;
				margin-top: 20px;
				padding: 0 18px;

				.goods_request_content {
					width: 100%;
					background-color: white;
					padding: 20px 24px;
					border-radius: 24px;

					.goods_request_titlediv {
						display: flex;
						align-items: center;

						.goods_request_titleline {
							width: 6px;
							height: 32px;
							background: #ff6685;
							border-radius: 3px;
						}

						.goods_request_title {
							margin-left: 6px;
							height: 40px;
							font-size: 32px;
							color: #262a33;
							line-height: 40px;
							font-weight: 600;
						}
					}

					.goods_request_detail {
						font-size: 30px;
						color: #262a33;
						line-height: 40px;
						margin-top: 10px;
						white-space: pre-wrap;
					}
				}

				.goods_request_item {
					width: 100%;
					display: flex;
					margin-top: 20px;

					/* background-color: red; */
					.goods_request_itemtitle {
						width: 128px;
						font-size: 28px;
						color: #8a8e99;
						line-height: 40px;
						text-align-last: justify;
						flex-shrink: 0;
					}

					.goods_request_itemdes {
						font-size: 28px;
						color: #262a33;
						line-height: 40px;
						width: 100%;
						margin-left: 40px;
						word-break: break-all;

						.flex {
							display: flex;
						}

						.channel-icon {
							width: 38px;
							height: 38px;
							margin-right: 8px;
						}

						.cuIcon-question {
							margin-top: 4px;
						}

						.tag-con {
							display: flex;
							flex-wrap: wrap;
							overflow: hidden;
						}

						.tag-item {
							line-height: 40px;
							font-size: 22px;
							padding: 0 10px;
							border-radius: 6px;
							margin-right: 8px;
							margin-bottom: 8px;
							background-color: #f5f5f5;
							color: #888;
						}

						.more-tag {
							display: flex;
							background-color: #f5f5f5;
							color: #888;
						}

						.cuIcon-unfold {
							font-size: 16px;
						}

						.tip-pop {
							position: absolute;
							left: calc(100% + 20px);
							bottom: 0;
							width: 320px;
							line-height: 34px;
							font-size: 22px;
							color: #888;
							padding: 12px 15px;
							background-color: #fff;
							box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
							border-radius: 10px;
						}

						.tip-pop::before {
							content: "";
							width: 15px;
							height: 15px;
							background-color: #fff;
							position: absolute;
							left: 0;
							bottom: 20px;
							transform: translateX(-50%) rotate(45deg);
						}

						.sanjiao {
							width: 15px;
							height: 15px;
							background-color: #fff;
							position: absolute;
							left: calc(100% + 20px);
							bottom: 20px;
							transform: translateX(-50%) rotate(45deg);
							box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
						}
					}
				}

				.goods_model_imgcontainer {
					width: 100%;
					height: 168px;
					overflow-x: scroll;
					margin-top: 20px;
					display: flex;

					.goods_model_imgdiv {
						height: 100%;
						width: 168px;
						overflow: hidden;
						border-radius: 10px;
						margin-left: 10px;
						flex-shrink: 0;
						position: relative;
						display: flex;
						justify-content: center;
						align-items: center;
						background: #000;

						&:first-child {
							margin-left: 0;
						}

						video {
							max-width: 100%;
							max-height: 100%;
							z-index: 1
						}

						.goods_model_img {
							position: relative;
							width: 100%;
							height: 100%;
							object-fit: cover;
						}

						.play-icon {
							width: 40px;
							height: 40px;
							position: absolute;
						}
					}
				}
			}

			.order_message_container {
				width: 100%;
				margin-top: 20px;
				background-color: white;
				padding: 30px 24px;
				box-sizing: border-box;

				.order_message_inputcontainer {
					display: flex;
					align-items: flex-end;

					.order_message_inputleft {
						width: 100%;
						height: 110px;
						background: #FFFFFF;
						border-radius: 16px;
						border: 1px solid #EBECF0;
						padding: 20px 20px;
						box-sizing: border-box;

						.order_message_input {
							width: 100%;
							height: 100%;
							font-size: 24px;
							padding: 0;

							::v-deep .van-field__body {
								height: 100%;

								.van-field__control {
									height: 100%;
									line-height: 42px;
								}
							}
						}
					}

					.order_message_inputbtn {
						margin-left: 30px;
						flex-shrink: 0;
						width: 110px;
						height: 50px;
						background: #000;
						border-radius: 25px;
						font-size: 26px;
						color: #FFFFFF;
						line-height: 50px;
						text-align: center;
					}
				}

				.order_message_table {
					width: 100%;

					.order_message_tablecell {
						width: 100%;
						display: flex;
						padding: 40px 0 0 0;

						.order_message_usericon {
							width: 66px;
							height: 66px;
							border-radius: 50%;
							background-color: red;
							flex-shrink: 0;
						}

						.order_message_rightdiv {
							width: 100%;
							display: flex;
							flex-direction: column;
							padding: 0 0 0 20px;

							.order_message_namediv {
								width: 100%;
								height: 40px;
								display: flex;
								justify-content: space-between;
								align-items: center;

								.order_message_shopdiv {
									font-size: 28px;
									font-weight: 400;
									color: #7A8399;
								}

								.order_message_time {
									font-size: 22px;
									font-weight: 400;
									color: #7A8399;
								}
							}

							.order_message_des {
								margin-top: 20px;
								font-size: 30px;
								font-weight: 400;
								color: #262A33;
								line-height: 45px;
							}
						}
					}
				}

				.order_message_moreview {
					width: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					margin-top: 30px;
				}
			}

			.order_flow_container {
				width: 100%;
				margin-top: 20px;
				padding-bottom: 150px;
				box-sizing: border-box;

				.order_flow_table {
					width: 100%;
					padding: 30px 0 0 0;
					box-sizing: border-box;
					background-color: white;

					.order_flow_tablecell {
						width: 100%;
						padding-left: 80px;
						padding-right: 30px;
						box-sizing: border-box;
						display: flex;

						.order_flow_left {
							display: flex;
							flex-direction: column;
							align-items: center;
							flex-shrink: 0;

							.order_flow_img {
								width: 20px;
								height: 20px;
								flex-shrink: 0;
								margin-top: 10px;
							}

							.order_flow_line {
								border-left: 2px dashed #C4C4CC;
								height: 100%;
							}
						}

						.order_flow_right {
							width: 100%;
							display: flex;
							flex-direction: column;
							padding: 0 0 15px 30px;

							.order_flow_des {
								font-size: 28px;
								font-weight: 400;
								color: #262A33;
								line-height: 40px;
							}

							.flex {
								display: flex;
								line-height: 40px;
								margin-top: 8px;

								.order_flow_time {
									font-size: 22px;
									font-weight: 400;
									color: #7A8399;
									margin-right: 20px;
								}

								.chat-btn {
									background-color: #000;
									color: #fff;
									font-size: 24px;
									height: 40px;
									line-height: 40px;
									border-radius: 20px;
									margin-left: 10px;
									padding: 0 20px;
								}
							}
						}
					}

					.order_flow_bottom {
						width: 100%;
						padding: 30px 0 20px 0;
						box-sizing: border-box;
					}
				}
			}

			.order_logistics_container {
				width: 100%;
				margin-top: 20px;
				background-color: white;

				.order_logistics_titlediv {
					width: 100%;
					height: 60px;
					background-color: #FCFCFC;
					padding: 0 24px;
					box-sizing: border-box;
					display: flex;
					align-items: center;
					justify-content: space-between;

					.order_logistics_typediv {
						display: flex;
						align-items: center;
						font-size: 24px;
						font-weight: 400;
						color: #262A33;

						.order_logistics_typenum {
							margin-left: 18px;
						}

						.change_btn {
							padding: 0 20px;
							background-color: #000;
							color: #fff;
							font-size: 24px;
							height: 40px;
							line-height: 40px;
							border-radius: 20px;
							margin-left: 20px;
						}
					}

					.order_logistics_statusdiv {
						font-size: 26px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #FF6666;
					}
				}

				.order_logistics_table {
					width: 100%;
					background-color: white;
					padding: 30px 0 0 0;
					box-sizing: border-box;

					.order_logistics_tablecell {
						width: 100%;
						padding: 0 24px;
						box-sizing: border-box;
						display: flex;

						.order_logistics_cellleft {
							display: flex;

							.order_logistics_timediv {
								width: 87px;
								flex-shrink: 0;

								.order_logistics_time {
									height: 30px;
									font-size: 22px;
									color: #262A33;
									line-height: 30px;
								}

								.order_logistics_subtime {
									height: 25px;
									font-size: 18px;
									color: #8A8E99;
									line-height: 25px;
								}
							}

							.order_logistics_linediv {
								height: 100%;
								width: 20px;
								display: flex;
								flex-direction: column;
								align-items: center;
								position: relative;

								.order_logistics_dian {
									width: 20px;
									height: 20px;
									background: #FF6666;
									border-radius: 50%;
									flex-shrink: 0;
								}

								.order_logistics_line {
									height: 100%;
									border-left: 2px dashed #C4C4CC;
								}
							}
						}

						.order_logistics_cellright {
							display: flex;
							flex-direction: column;
							padding: 0 0 40px 24px;
							box-sizing: border-box;

							.order_logistics_status {
								height: 33px;
								font-size: 24px;
								font-weight: 400;
								color: #4DA6FF;
								line-height: 33px;
							}

							.order_logistics_des {
								font-size: 22px;
								font-weight: 400;
								color: #262A33;
								line-height: 30px;
								padding-top: 20px;
							}
						}
					}
				}
			}

			.order_message_morediv {
				display: flex;
				height: 50px;
				justify-content: center;
				align-items: center;

				.order_message_moretitle {
					height: 30px;
					font-size: 22px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #8A8E99;
					line-height: 30px;
				}

				.order_message_moreicon {
					width: 13px;
					height: 8px;
					/* background-color: red; */
					margin-left: 10px;
				}
			}

			.order_wx_container {
				margin-top: 20px;
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				background-color: white;
				padding: 30px 24px;

				.order_wx_leftdiv {
					display: flex;
					flex-direction: column;

					.order_wx_title {
						height: 40px;
						font-size: 28px;
						color: #262A33;
						line-height: 40px;
					}

					.order_wx_subtitle {
						font-size: 22px;
						color: #B8BECC;
						line-height: 30px;
						margin-top: 10px;
					}
				}

				.order_wx_copy {
					flex-shrink: 0;
					width: 110px;
					height: 50px;
					background: #000;
					border-radius: 25px;
					font-size: 26px;
					color: #FFFFFF;
					line-height: 50px;
					text-align: center;
				}
			}

			.dispute_container {
				margin-top: 20px;
				background-color: white;
				padding: 20px 24px;

				.dispute-title {
					line-height: 60px;
					display: flex;
					justify-content: space-between;
					align-items: center;

					.title_main {
						font-size: 34px;
						font-weight: 700;
					}

					.dispute_state {
						font-size: 24px;
						color: #aaa;
					}
				}

				.dispute-reason {
					border: 1px solid #EBECF0;
					border-radius: 20px;
					padding: 20px;
					line-height: 40px;

					.dispute_time {
						font-size: 24px;
						color: #aaa;
					}
				}
			}

			.order_imagdiv_container {
				margin-top: 20px;
				background-color: white;
				padding: 10px 0 20px;

				.audit-status-con {
					font-size: 30px;
					display: flex;
					justify-content: space-between;
					padding: 0 20px;

					.flex {
						display: flex;
						align-items: center;
						line-height: 50px;

						.van-icon {
							color: #00C800;
							margin-right: 6px;
						}

						.van-icon-clear {
							color: red;
						}
					}

					.re_baogao {
						font-size: 28px;
						color: #fff;
						width: 180px;
						text-align: center;
						line-height: 50px;
						background-color: #000;
						border-radius: 25px;
					}
				}

				.order_imagdiv_content {
					padding: 10px 18px;
					display: flex;
					flex-wrap: wrap;

					.order_imagdiv_item {
						width: 25%;
						height: 200px;
						padding: 6px;
						box-sizing: border-box;
						position: relative;
						display: flex;
						justify-content: center;
						align-items: center;

						.video_item {
							width: 100%;
							height: 100%;
							border-radius: 5px;
							overflow: hidden;
							position: relative;
							background: #333;
							display: flex;
							justify-content: center;
							align-items: center;
						}

						.play-icon {
							width: 40px;
							height: 40px;
							position: absolute;
						}

						.order_imagdiv {
							width: 100%;
							height: 100%;
							border-radius: 5px;
							overflow: hidden;
							display: block;
							object-fit: cover;
						}
					}
				}

				.order_imagdiv_itemtitle {
					padding: 0 24px;
					display: flex;
					height: 60px;
					line-height: 60px;
					justify-content: space-between;
				}

				.order_imagdiv_time {
					padding: 0 24px;
					font-size: 26px;
				}

				.baogao-link {
					margin: 20px 24px;

					.link,
					.url {
						border-radius: 10px;
						background-color: #f7f7f7;
						padding: 20px;
						color: #666;
						word-wrap: break-word;
						line-height: 30px;
					}

					.url {
						margin-bottom: 15px;
					}
				}
			}

			.upload_task_wrap {
				height: 250px;
				width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				background: #fff;
				margin: 20px 0;

				.upload_task {
					width: 45%;
					line-height: 80px;
					text-align: center;
					background: #000;
					border-radius: 40px;
					color: #fff;
				}
			}

			.goods_model_content {
				margin-top: 20px;
				background-color: white;
				padding: 30px 25px;

				.goods_request_titlediv {
					display: flex;
					align-items: center;

					.goods_request_titleline {
						width: 6px;
						height: 32px;
						background: #ff6685;
						border-radius: 3px;
					}

					.goods_request_title {
						margin-left: 6px;
						height: 40px;
						font-size: 32px;
						color: #262a33;
						line-height: 40px;
						font-weight: 600;
					}
				}
			}

		}
	}

	::v-deep .van-overlay {
		background-color: rgba(0, 0, 0, .4);
	}

	.van-dialog {
		width: 570px;
		font-size: 30px;
		padding: 40px;

		::v-deep .van-dialog__header {
			font-size: 34px;
			font-weight: 600;
			padding-top: 15px;
		}

		::v-deep .van-dialog__content {
			margin-top: 50px;
			text-align: center;

			.reason-input {
				width: 100%;
				height: 130px;
				border-radius: 10px;
				background-color: #f7F7F7;
				padding: 15px 20px;
				line-height: 40px;
				font-size: 26px;
				text-align: left;

				&.xhs {
					height: 200px;
				}
			}

			.xhs_tip {
				font-size: 22px;
				text-align: right;
				margin-bottom: 20px;
				color: blue;
				display: flex;
				align-items: center;
				justify-content: flex-end;

				.van-icon {
					font-size: 22px;
				}
			}

			.reason-tip {
				margin-top: 20px;
				line-height: 32px;
				font-size: 22px;
				text-align: left;

				.text-gray {
					color: #8c8c8c;
				}

				.text-red {
					color: red;
				}
			}

			.report-tip {
				font-size: 24px;
				color: #aaa;
				line-height: 30px;

				&.dispute {
					margin-top: 20px;
					text-align: left;
				}
			}

			.report-type-con {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				padding: 10px 0;

				.report-type-item {
					width: calc(50% - 8px);
					line-height: 70px;
					border-radius: 10px;
					margin: 8px 0;
					background-color: #F1F1F1;
					color: #888;
					border: 1px solid #F1F1F1;
					box-sizing: border-box;

					&.sel_type {
						color: #FF6685;
						border: 1px solid #FF6685;
						background-color: #FFEBEF;
					}
				}
			}

			.upload_wrap {
				margin-top: 20px;
				text-align: left;
				display: flex;

				.upload-item {
					width: 180px;
					height: 180px;
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					background-color: #f1f1f1;
					border-radius: 10px;

					.upload-icon {
						width: 50px;
						height: 50px;
						margin-bottom: 10px;
					}

					.upload-text {
						color: #888;
						font-size: 24px;
					}
				}


				.image-item {
					width: 32%;
					height: 156px;
					border-radius: 6px;
					overflow: hidden;
					margin-bottom: 10px;
					display: flex;
					justify-content: center;
					flex-direction: column;
					align-items: center;
					color: #aaa;
					position: relative;
					margin-left: 10px;

					.cuIcon-close {
						position: absolute;
						top: 0;
						right: 0;
						background-color: rgba(0, 0, 0, 0.5);
						color: #fff;
						font-size: 24px;
						width: 36px;
						height: 36px;
						line-height: 36px;
						text-align: center;
						border-bottom-left-radius: 6px;
						border-top-right-radius: 6px;
					}
				}
			}

		}

		::v-deep .van-dialog__footer {
			margin-top: 50px;
			justify-content: center;

			.van-button {
				font-size: 32px;
				height: 68px;
				border-radius: 50px;
				flex: none;
				width: 220px;

				&.van-dialog__confirm {
					background-color: #000;
					color: #fff;
				}

				&.van-dialog__cancel {
					border: 1px solid #8c8c8c;
					margin-right: 40px;
				}
			}
		}
	}

	.van-popup {
		padding: 40px;
		text-align: center;

		.title {
			font-size: 35px;
			font-weight: 600;
			text-align: center;
			margin-bottom: 40px;
		}

		.report-tip {
			font-size: 24px;
			color: #aaa;
			margin: 20px 0;
			text-align: left;
			line-height: 30px;
		}

		.reason-input {
			width: 100%;
			border-radius: 10px;
			background-color: #f7F7F7;
			padding: 15px 20px;
			line-height: 40px;
			font-size: 28px;
			text-align: left;
		}

		.company-container {
			display: flex;
			flex-wrap: wrap;

			.company-item-con {
				width: 25%;
				padding: 8px;

				.company-item {
					line-height: 60px;
					width: 150px;
					text-align: center;
					border: 1px solid #F7F8FA;
					border-radius: 10px;
					background-color: #F7F8FA;
					color: #888;

					&.select_company {
						background-color: #FFEBEF;
						border-color: #FF6685;
						color: #FF6685;
					}

					&:nth-child(4n) {
						margin-right: 0;
					}
				}
			}
		}

		.van-button {
			padding: 0 150px;
			font-size: 30px;
			height: 75px;
			background-color: #000;
			border-color: #000;
			margin-top: 40px;
		}
	}
</style>
