<template>
    <div>
        <div class="container">
            <div class="order_title">
                <div class="back_img">
                    <img class="back_img" src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/normal_icon/Back%403x.png" @click="back_list" alt />
                </div>
                我的订单
            </div>
            <div class="search_wrap">
                <div class="inp_wrap">
                    <img @click="search" src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/normal_icon/normal_fdj_icon.png" class="search_icon" />
                    <van-field v-model="keyword" placeholder="商品标题、订单编号" />
                    <img @click="change_show_real" src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/authentication_icon/authentication_cha_icon.png" class="search_cha" />
                </div>
            </div>

            <div class="tab_wrap">
                <van-tabs v-model="currentNav" @change="tabSelect">
                    <van-tab v-for="(item, index) in navBar" :key="index" :title="item.title" :name="item.type">
                        <div class="nav_tip" v-if="item.showTip">
                            <div class="tips">
                                <van-icon name="question-o" />
                                {{ item.tip }}
                            </div>
                            <van-icon name="cross" @click="hideTip(index)" />
                        </div>
                    </van-tab>
                </van-tabs>
            </div>

            <div class="order-scroll-container">
                <scroll-y class="scroll_wrap" @loadMore="getOrderList" ref="iscroll">
                    <div v-if="isNull" class="empty-wrapper">
                        <div>
                            <img class="image_empty" src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/item_list_icon/empty%403x.png" />
                            <div class="title">暂无订单~</div>
                        </div>
                    </div>
                    <div v-else-if="orderList.length" class="order_list_wrap">
                        <div class="order-item-con" v-for="(item, index) in orderList" :key="index">
                            <div class="dispute-state" v-if="item.is_dispute == 1">
                                <div class="flex">
                                    <text class="cuIcon-community text-white"></text>
                                    <div>
                                        纠纷状态：{{
                                            item.state == 1
                                                ? "纠纷中"
                                                : "已撤销"
                                        }}
                                    </div>
                                </div>
                                <div @click="showModel(item, 'disputereason')">
                                    查看原因
                                </div>
                            </div>

                            <div class="order-item">
                                <div class="order-item-top">
                                    <div class="flex-wrapper">
                                        <div class="order-sn" v-if="item.status == 1">
                                            通告编号：{{ item.task_sn }}
                                        </div>
                                        <div class="order-sn" v-else>
                                            订单编号：{{ item.order_sn }}
                                        </div>
                                        <span class="
                                                iconfont
                                                icon-fuzhi1
                                                cuIcon-copy
                                            " name="description" @click="
                                                copyContent(
                                                    item.status == 1
                                                        ? item.task_sn
                                                        : item.order_sn
                                                )
                                            "></span>
                                    </div>
                                    <div class="order-status">
                                        {{ orderStatus[item.status] }}
                                    </div>
                                </div>

                                <div class="order-item-center" @click="toOrderDetail(item)">
                                    <img class="order_goods_icon" v-lazy="item.goods_pic[0]" />
                                    <div class="goods-msg">
                                        <div class="tag_info">
                                            <div class="goods-name">
                                                {{ item.goods_name }}
                                            </div>
                                            <task-tag :task_require="
                                                    item.task_require
                                                " :task_require_sub="
                                                    item.task_require_sub
                                                " :task_type="item.task_type" :xhs_prom_type="
                                                    item.xhs_prom_type
                                                ">
                                            </task-tag>
                                        </div>
                                        <div class="goods-other">
                                            <div class="wechat-con" v-if="
                                                    item.status != 1 &&
                                                    item.status != 2 &&
                                                    item.status != 11 &&
                                                    item.status != 12 &&
                                                    item.status != 13 &&
                                                    item.status != 14 &&
                                                    item.status != 15
                                                ">
                                                <div class="wechat_wrap">
                                                    <span class="span">商家微信</span>
                                                    {{
                                                        item.connect_type == 1
                                                            ? item.wechat.substring(
                                                                  0,
                                                                  3
                                                              ) +
                                                              "****" +
                                                              item.wechat.substring(
                                                                  7
                                                              )
                                                            : "无"
                                                    }}
                                                </div>
                                                <div v-if="
                                                        item.wechat &&
                                                        item.connect_type == 1
                                                    " class="wechat-copy" @click.stop="
                                                        copyContent(item.wechat)
                                                    ">
                                                    复制
                                                </div>
                                                <div class="connect-btn" @click.stop="toChat(item)">
                                                    <van-icon name="chat-o" />
                                                    联系商家
                                                </div>
                                            </div>
                                            <div class="countdown-time" v-if="item.countdown_time">
                                                <div>
                                                    <template>{{
                                                        item.countdown_time
                                                    }}</template>
                                                    <template v-if="item.status == 5">（承诺{{
                                                            item.promise_commit_time
                                                        }}h）</template>
                                                </div>
                                                <van-icon name="question-o" v-if="item.status == 8" class="question-icon" @click.stop="
                                                        showModel(
                                                            item,
                                                            'countdown'
                                                        )
                                                    " />
                                            </div>
                                            <div v-if="item.status != 15" class="price">
                                                <span class="span">佣金</span>
                                                {{ item.mt_price / 100 }}元
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="order-item-bottom">
                                    <div class="report-con" @click="showModel(item, 'report')">
                                        <div class="iconfont icon-jubao2"></div>
                                        <div>
                                            {{
                                                item.has_report == 1
                                                    ? "已反馈"
                                                    : "反馈"
                                            }}
                                        </div>
                                    </div>
                                    <div class="order-btn-con">
                                        <template v-if="
                                                currentNav == 40 &&
                                                item.is_dispute == 1
                                            ">
                                            <div class="order-btn" v-if="
                                                    item.user_appeal_time == 0
                                                " @click="
                                                    showModel(item, 'dispute')
                                                ">
                                                立即申诉
                                            </div>
                                            <div class="order-btn" v-else>
                                                已申诉
                                            </div>
                                            <div class="order-btn" @click="toOrderDetail(item)">
                                                去处理
                                            </div>
                                        </template>
                                        <template v-if="currentNav != 40">
                                            <template v-if="item.status == 1">
                                                <div class="order-btn" @click="
                                                        showModel(
                                                            item,
                                                            'cancel'
                                                        )
                                                    ">
                                                    取消报名
                                                </div>
                                            </template>
                                            <template v-if="item.status == 18">
                                                <div class="order-btn" @click="
                                                        showModel(
                                                            item,
                                                            'cancelorder'
                                                        )
                                                    ">
                                                    申请取消
                                                </div>
                                                <div class="order-btn red-btn" @click="
                                                        showModel(
                                                            item,
                                                            'contact'
                                                        )
                                                    ">
                                                    确认沟通
                                                </div>
                                            </template>
                                            <template v-if="item.status == 3">
                                                <div class="order-btn" @click="
                                                        showModel(
                                                            item,
                                                            'cancelorder'
                                                        )
                                                    ">
                                                    放弃任务
                                                </div>
                                                <div class="order-btn red-btn" @click="showPopup(item)">
                                                    上传物流单号
                                                </div>
                                            </template>
                                            <template v-if="item.status == 4">
                                                <div class="order-btn red-btn" @click="
                                                        showModel(
                                                            item,
                                                            'receive'
                                                        )
                                                    ">
                                                    确认收货
                                                </div>
                                            </template>
                                            <template v-if="item.status == 5">
                                                <div v-if="
                                                        item.task_require == 4
                                                    " class="order-btn" @click="
                                                        showModel(item, 'xhs')
                                                    ">
                                                    上传笔记
                                                </div>
                                                <div v-else class="order-btn" @click="
                                                        toCommitBaogao(item)
                                                    ">
                                                    上传报告
                                                </div>
                                            </template>
                                            <template v-if="
                                                    item.status == 6 ||
                                                    item.status == 7 ||
                                                    item.status == 9 ||
                                                    item.status == 10
                                                ">
                                                <div class="order-btn" @click="toOrderDetail(item)">
                                                    查看详情
                                                </div>
                                            </template>
                                            <template v-if="item.status == 8">
                                                <div class="order-btn red-btn" @click="showPopup(item)">
                                                    填写物流
                                                </div>
                                            </template>
                                            <template v-if="item.status == 15">
                                                <div class="order-btn" @click="
                                                        showModel(
                                                            item,
                                                            'reject'
                                                        )
                                                    ">
                                                    拒绝
                                                </div>
                                                <div class="order-btn red-btn" @click="
                                                        showModel(
                                                            item,
                                                            'invite'
                                                        )
                                                    ">
                                                    同意
                                                </div>
                                            </template>
                                            <template v-if="item.status == 17">
                                                <div class="order-btn" @click="
                                                        showModel(
                                                            item,
                                                            'reason'
                                                        )
                                                    ">
                                                    查看原因
                                                </div>
                                            </template>
                                            <template v-if="
                                                    item.status == 2 ||
                                                    item.status == 11 ||
                                                    item.status == 12 ||
                                                    item.status == 13 ||
                                                    item.status == 14 ||
                                                    item.status == 16
                                                ">
                                                <div class="order-btn" @click="
                                                        showModel(
                                                            item,
                                                            'delete'
                                                        )
                                                    ">
                                                    删除订单
                                                </div>
                                            </template>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="nomore" v-if="stopLoad">
                            没有更多订单了~
                        </div>
                    </div>
                    <van-loading color="#1989fa" type="spinner" v-else-if="isLoading" />

                    <span slot="popup">
                        <van-popup v-model="showExpress" round position="bottom">
                            <div class="title">填写物流信息</div>
                            <div class="report-tip">
                                请填写商家发货给你的物流单号！收到货后请在48小时内拍好并反馈给商家！超时有可能会被限制报名！
                            </div>
                            <van-field class="reason-input" v-model="expressno" placeholder="请填写物流单号" />
                            <div class="report-tip">选择快递公司</div>
                            <div class="company-container">
                                <div class="company-item-con" v-for="(item, index) in expressList" :key="index">
                                    <div :class="[
                                            'company-item',
                                            current == index
                                                ? 'select_company'
                                                : '',
                                        ]" @click="chooseCompany(index)">
                                        {{ item.kd_company }}
                                    </div>
                                </div>
                            </div>
                            <van-button round type="primary" @click="expressSave">确认</van-button>
                        </van-popup>

                        <van-popup v-model="showInvitePopup" position="right" :style="{ width: '100%' }" class="van-popup-invite">
                            <div class="invite_title">
                                <div class="invite_img">
                                    <img class="invite_img" src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/normal_icon/Back%403x.png" @click="closeInvite" alt />
                                </div>
                                同意邀请
                            </div>
                            <div class="invite_wrap">
                                <div class="invite_main">
                                    <div class="main_item" v-if="
                                            orderInfo &&
                                            orderInfo.task_type == 1
                                        ">
                                        <div class="invite_sub">
                                            <img style="
                                                    height: 40px;
                                                    width: 40px;
                                                " src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/goods_icon/invite_goods%403x.png" />
                                            <div class="invite_sub_title">
                                                商品赠送
                                            </div>
                                        </div>
                                        <div class="invite_des">
                                            但您也可以要求另加佣金，不填则为0。
                                        </div>
                                    </div>
                                    <div class="main_item" v-else-if="
                                            orderInfo &&
                                            orderInfo.task_type == 2
                                        ">
                                        <div class="invite_sub">
                                            <img src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/goods_icon/invite_goods%403x.png" />
                                            <div class="invite_sub_title">
                                                商品寄拍
                                            </div>
                                        </div>
                                        <div class="invite_des"></div>
                                    </div>
                                    <div class="main_item">
                                        <div class="inp_title">额外佣金</div>
                                        <van-field class="inp" v-model="price" placeholder="请输入报价" />
                                        <div class="inp_info">
                                            　元
                                            <div class="no_remember" style="display: none"></div>
                                            <div class="remember" style="display: none"></div>
                                        </div>
                                    </div>

                                    <div class="main_item">
                                        <div class="inp_title">时间承诺</div>
                                        <van-field class="inp" v-model="time" placeholder="请输入时间" />
                                        <div class="inp_info">
                                            小时
                                            <div class="no_remember" style="display: none"></div>
                                            <div class="remember" style="display: none"></div>
                                        </div>
                                    </div>
                                </div>

                                <div class="tip-container" v-if="orderInfo && orderInfo.task_type == 2">
                                    <van-icon name="question-o" class="cuIcon-question" />
                                    商家审核通过后，请在48小时内寄回商品，否则将被限制报名!
                                </div>

                                <div class="btn" @click="mt_apply_btn">
                                    同意邀请
                                </div>
                            </div>
                        </van-popup>

                        <van-popup v-model="showXhsUrl" position="right" :style="{ width: '80%' }">
                            <img :src="xhs_url" style="width: 100%" />
                        </van-popup>
                    </span>
                </scroll-y>
            </div>

            <van-dialog v-model="showCancelApply" title="温馨提示" show-cancel-button @confirm="cancelApplyHandler">
                确认要取消报名吗？
            </van-dialog>

            <van-dialog v-model="showContact" title="温馨提示" show-cancel-button @confirm="contactHandler">
                与商家沟通好后请确认，通告将进入待发货状态！
            </van-dialog>

            <van-dialog v-model="showReceive" title="温馨提示" show-cancel-button @confirm="receiveHandler">
                确认已经收到货了吗？
            </van-dialog>

            <van-dialog v-model="showCancelReason" :title="disputeReason ? '纠纷原因' : '取消原因'" confirm-button-text="我知道了" @confirm="hideModel">
                {{ cancelReason || disputeReason || "无" }}
            </van-dialog>

            <van-dialog v-model="showCountDown" title="说明" confirm-button-text="我知道了" @confirm="hideModel">
                请在寄回倒计时内寄出，超时将被限制报名
            </van-dialog>

            <van-dialog v-model="showDelete" title="温馨提示" show-cancel-button @confirm="deleteHandler">
                确认要删除该订单吗？
            </van-dialog>

            <van-dialog v-model="showCancelOrder" title="确认取消该订单吗？" show-cancel-button :before-close="onBeforeClose" @confirm="cancelApplyHandler">
                <van-field class="reason-input" v-model="cancelReason" rows="2" autosize type="textarea" placeholder="请输入取消原因" />
                <div class="reason-tip">
                    <div class="text-gray">
                        提交申请后，订单将移至“取消中”，请等待商家审核。
                    </div>
                    <div class="text-red">
                        请勿恶意取消进行中的订单，否则将可能被平台清退。
                    </div>
                </div>
            </van-dialog>

            <van-dialog v-model="showReport" title="提交反馈" show-cancel-button @confirm="reportOrder" :before-close="onBeforeClose">
                <div class="report-tip">
                    收到您的反馈后，我们将认真审查此通告
                </div>
                <div class="report-type-con">
                    <div v-for="(item, index) in reportTypes" :class="[
                            'report-type-item',
                            index == reportIndex ? 'sel_type' : '',
                        ]" :key="index" @click="chooseReportType(index)">
                        {{ item.text }}
                    </div>
                </div>
                <van-field class="reason-input" v-model="reportReason" rows="2" autosize type="textarea" placeholder="请输入反馈描述" />
                <div class="upload_wrap">
                    <van-uploader multiple :after-read="afterRead" :previewImage="false">
                        <div class="upload-item">
                            <img class="upload-icon" src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/edit_album/upload_image%403x.png" />
                            <div class="upload-text">上传凭证</div>
                        </div>
                    </van-uploader>
                    <div class="img-item" v-for="(item, index) in fileList" :key="index">
                        <img class="img" :src="item" />
                        <van-icon name="cross" class="cuIcon-close" @click="deleteImage(index)" />
                    </div>
                </div>
            </van-dialog>

            <van-dialog v-model="showInviteReject" title="拒绝邀请" show-cancel-button :before-close="onBeforeClose" @confirm="refuseInvite">
                <van-field class="reason-input" v-model="refuseReason" rows="2" autosize type="textarea" placeholder="请输入拒绝原因" />
            </van-dialog>

            <van-dialog v-model="showXhsNote" title="提交笔记" show-cancel-button :before-close="onBeforeClose" @confirm="commitNoteLink">
                <div class="xhs_tip" @click="toCourse">
                    <van-icon name="question-o" class="cuIcon-question" />如何获取笔记链接
                </div>
                <van-field class="reason-input xhs" v-model="note_link" rows="3" autosize type="textarea" placeholder="请输入已发布笔记链接" />
                <div class="reason-tip">
                    <div class="text-red">
                        未经商家允许，不许在笔记保留时间内删除 （{{
                            orderInfo && orderInfo.xhs_forever_save == 1
                                ? "永久"
                                : orderInfo && orderInfo.xhs_save_time + "个月"
                        }}） ，否则将视为违规
                    </div>
                </div>
            </van-dialog>

            <van-dialog v-model="showDispute" title="立即申诉" show-cancel-button @confirm="commitDispute">
                <van-field class="reason-input" v-model="disputeReason" rows="2" autosize type="textarea" placeholder="请输入申诉说明" />
                <div class="report-tip dispute">
                    如遇到商家恶意投诉等情况，您可以进行申诉，客服会及时跟进处理。
                </div>
                <div class="upload_wrap">
                    <van-uploader multiple :after-read="afterRead" :previewImage="false">
                        <div class="upload-item">
                            <img class="upload-icon" src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/edit_album/upload_image%403x.png" />
                            <div class="upload-text">上传凭证</div>
                        </div>
                    </van-uploader>

                    <div class="img-item" v-for="(item, index) in fileList" :key="index">
                        <img class="img" :src="item" />
                        <van-icon name="cross" class="cuIcon-close" @click="deleteImage(index)" />
                    </div>
                </div>
            </van-dialog>

            <transition enter-active-class="slide_in" leave-active-class="slide_out">
                <router-view class="commit_baogao"></router-view>
            </transition>
        </div>
    </div>
</template>

<script>
import ScrollY from "../components/app-scroll-y";
import taskTag from "../components/task-tag";
import orderListDataMixin from "./order/mixin/orderListDataMixin";

export default {
    name: "OrderList",
    components: {
        ScrollY,
        taskTag,
    },
    mixins: [orderListDataMixin],
};
</script>

<style lang="scss" scoped>
.container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.order_title {
    width: 100%;
    height: 80px;
    display: flex;
    font-size: 35px;
    align-items: center;
    padding: 20px;
    justify-content: center;
    position: relative;

    .back_img {
        position: absolute;
        left: 20px;
        width: 50px;
        height: 50px;
        background-color: rgba(220, 220, 220, 0.5);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            height: 80%;
            width: 80%;
            margin-left: -10px;
            opacity: 0.6;
        }
    }
}

.search_wrap {
    padding: 10px 24px;

    .inp_wrap {
        background: #fff;
        border-radius: 10px;
        height: 70px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding: 0 15px;

        .van-field {
            height: 100%;
            padding: 10px 20px;
        }

        ::v-deep .van-field__body {
            height: 100%;

            input {
                height: 100%;
                font-size: 30px;

                &::placeholder {
                    color: #b8becc;
                    font-size: 26px;
                }
            }
        }

        .search_icon {
            height: 36px;
            width: 36px;
            margin-right: 15px;
        }

        .search_cha {
            height: 36px;
            width: 36px;
            border-radius: 50%;
        }
    }
}

.tab_wrap {
    padding-bottom: 15px;

    ::v-deep .van-tabs__wrap {
        padding: 0 20px;
        height: 60px;

        .van-tabs__nav {
            background: transparent;

            .van-tab {
                font-size: 30px;
                color: #262a33;
                padding: 0 20px;

                &.van-tab--active {
                    color: #ff6685;
                    font-weight: 600;
                }
            }

            .van-tabs__line {
                background: #ff6685;
                width: 80px;
                height: 4px;
            }
        }
    }

    .nav_tip {
        margin-top: 10px;
        line-height: 50px;
        background-color: #b0997e;
        color: #fff;
        font-size: 24px;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .tips {
            color: #fff;
            display: flex;
            align-items: center;
        }

        .van-icon,
        .van-icon-cross {
            font-size: 30px;
            margin-right: 10px;
            vertical-align: sub;
        }
    }
}

.order-scroll-container {
    height: 100vh;

    .sc_view {
        overflow: hidden;
    }

    .scroll_wrap {
        height: 84vh;
        position: relative;

        .van-loading {
            position: absolute;
            left: 50%;
            padding-top: 200px;
            transform: translateX(-30px) translateY(-30px);

            .van-loading__spinner {
                width: 60px;
                height: 60px;
            }
        }
    }

    .order-item-con {
        background-color: #bfbfbf;
        border-radius: 20px;
        margin: 0 24px 12px;
    }

    .dispute-state {
        line-height: 60px;
        color: #ffffff;
        padding: 0 20px;
        font-size: 26px;
        display: flex;
        justify-content: space-between;

        .cuIcon-community {
            margin-right: 8px;
            font-size: 28px;
        }
    }

    .order-item {
        border-radius: 20px;
        background-color: #fff;
        padding: 20px;

        .order-item-top {
            display: flex;
            justify-content: space-between;
            font-size: 28px;
            line-height: 40px;

            .flex-wrapper {
                display: flex;
            }

            .order-sn {
                color: #666;
            }

            .cuIcon-copy {
                padding: 0 6px;
                color: #666;
                line-height: 40px;
                font-size: 24px;
            }

            .order-status {
                color: #ff6685;
            }
        }

        .order-item-center {
            display: flex;
            justify-content: space-between;
            padding: 15px 0;

            .order_goods_icon {
                width: 200px;
                height: 200px;
                border-radius: 10px;
                flex-shrink: 0;
                overflow: hidden;
                object-fit: cover;
            }

            .goods-msg {
                flex: 1;
                margin-left: 15px;
                display: flex;
                flex-direction: column;
                overflow: hidden;
                justify-content: space-between;

                .goods-other {
                    font-size: 24px;

                    .wechat-con {
                        line-height: 40px;
                        display: flex;
                        align-items: center;
                        margin-top: 10px;
                        flex-wrap: wrap;

                        .wechat-copy {
                            padding: 0 10px;
                            height: 28px;
                            line-height: 28px;
                            background-color: #cfd6e6;
                            border-radius: 5px;
                            font-size: 20px;
                            color: white;
                            margin-left: 10px;
                        }

                        .span {
                            color: #888;
                            margin-right: 4px;
                        }

                        .connect-btn {
                            line-height: 36px;
                            border-radius: 20px;
                            padding: 0 10px;
                            border: 1px solid #ff6685;
                            color: #ff6685;
                            display: flex;
                            align-items: center;
                            font-size: 22px;
                            margin-left: 10px;

                            .van-icon {
                                font-size: 26px;
                                color: #ff6685;
                                margin-right: 5px;
                            }
                        }
                    }

                    .countdown-time {
                        font-size: 24px;
                        color: #ff6685;
                        display: flex;
                        line-height: 40px;
                        align-items: center;

                        .question-icon {
                            font-size: 30px;
                            margin-left: 4px;
                        }
                    }

                    .price {
                        margin-top: 10px;
                    }
                }

                .goods-name {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    margin-bottom: 10px;
                    font-size: 28px;
                    line-height: 36px;
                }
            }
        }

        .order-item-bottom {
            display: flex;
            justify-content: space-between;
            padding-top: 10px;
            align-items: center;

            .order-btn-con {
                display: flex;
            }

            .report-con {
                line-height: 50px;
                color: #888;
                font-size: 26px;
                display: flex;

                .icon-jubao2 {
                    color: #888;
                    margin-right: 3px;
                    font-size: 28px;
                }
            }

            .order-btn {
                border: 1px solid #ddd;
                line-height: 60px;
                padding: 0 24px;
                border-radius: 30px;
                box-sizing: content-box;
                color: #888;
                margin-left: 15px;
                font-size: 28px;

                &.red-btn {
                    background-color: #ff6685;
                    border-color: #ff6685;
                    color: #fff;
                }

                &:first-child {
                    margin: 0;
                }
            }
        }
    }

    .empty-wrapper {
        height: 50vh;
        display: flex;
        justify-content: center;
        align-items: center;

        .image_empty {
            width: 300px;
            height: 300px;
        }

        .title {
            text-align: center;
            font-size: 28px;
            color: #262a33;
            margin-top: 13px;
        }
    }
}

.nomore {
    line-height: 40px;
    padding-bottom: 80px;
    text-align: center;
    color: #888;
    font-size: 28px;
}

/* 弹框相关 */

::v-deep .van-overlay {
    background-color: rgba(0, 0, 0, 0.4);
}

.van-dialog {
    width: 570px;
    font-size: 30px;
    padding: 30px 40px;

    ::v-deep .van-dialog__header {
        font-size: 34px;
        font-weight: 600;
        padding-top: 15px;
    }

    ::v-deep .van-dialog__content {
        margin-top: 40px;
        text-align: center;
        line-height: 40px;

        .reason-input {
            width: 100%;
            height: 130px;
            border-radius: 10px;
            background-color: #f7f7f7;
            padding: 15px 20px;
            line-height: 40px;
            font-size: 26px;
            text-align: left;

            &.xhs {
                height: 200px;
            }
        }

        .reason-tip {
            margin-top: 20px;
            line-height: 32px;
            font-size: 22px;
            text-align: left;

            .text-gray {
                color: #8c8c8c;
            }

            .text-red {
                color: red;
            }
        }

        .xhs_tip {
            font-size: 22px;
            text-align: right;
            margin-bottom: 20px;
            color: blue;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .van-icon {
                font-size: 22px;
            }
        }

        .report-tip {
            font-size: 24px;
            color: #aaa;
            line-height: 30px;

            &.dispute {
                margin-top: 20px;
                text-align: left;
            }
        }

        .report-type-con {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            padding: 10px 0;

            .report-type-item {
                width: calc(50% - 8px);
                line-height: 70px;
                border-radius: 10px;
                margin: 8px 0;
                background-color: #f1f1f1;
                color: #888;
                border: 1px solid #f1f1f1;
                box-sizing: border-box;

                &.sel_type {
                    color: #ff6685;
                    border: 1px solid #ff6685;
                    background-color: #ffebef;
                }
            }
        }

        .upload_wrap {
            margin-top: 20px;
            text-align: left;
            display: flex;
            flex-wrap: wrap;

            .upload-item {
                width: 150px;
                height: 150px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                background-color: #f1f1f1;
                border-radius: 10px;
                margin-right: 10px;
                margin-bottom: 10px;

                .upload-icon {
                    width: 50px;
                    height: 50px;
                    margin-bottom: 10px;
                }

                .upload-text {
                    color: #888;
                    font-size: 24px;
                }
            }

            .img-item {
                width: 30%;
                height: 150px;
                border-radius: 6px;
                overflow: hidden;
                margin-bottom: 10px;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                color: #aaa;
                position: relative;
                margin-right: 10px;

                .img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                .cuIcon-close {
                    position: absolute;
                    top: 0;
                    right: 0;
                    background-color: rgba(0, 0, 0, 0.5);
                    color: #fff;
                    font-size: 24px;
                    width: 36px;
                    height: 36px;
                    line-height: 36px;
                    text-align: center;
                    border-bottom-left-radius: 6px;
                    border-top-right-radius: 6px;
                }
            }
        }
    }

    ::v-deep .van-dialog__footer {
        margin-top: 50px;
        justify-content: center;

        .van-button {
            font-size: 32px;
            height: 68px;
            border-radius: 50px;
            flex: none;
            width: 220px;

            &.van-dialog__confirm {
                background-color: #000;
                color: #fff;
            }

            &.van-dialog__cancel {
                border: 1px solid #8c8c8c;
                margin-right: 40px;
            }
        }
    }
}

.van-popup {
    padding: 40px;
    text-align: center;

    .title {
        font-size: 35px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 40px;
    }

    .report-tip {
        font-size: 24px;
        color: #aaa;
        margin: 20px 0;
        text-align: left;
        line-height: 30px;
    }

    .reason-input {
        width: 100%;
        border-radius: 10px;
        background-color: #f7f7f7;
        padding: 15px 20px;
        line-height: 40px;
        font-size: 28px;
        text-align: left;
    }

    .company-container {
        display: flex;
        flex-wrap: wrap;

        .company-item-con {
            width: 25%;
            padding: 8px;

            .company-item {
                line-height: 60px;
                width: 150px;
                text-align: center;
                border: 1px solid #f7f8fa;
                border-radius: 10px;
                background-color: #f7f8fa;
                color: #888;

                &.select_company {
                    background-color: #ffebef;
                    border-color: #ff6685;
                    color: #ff6685;
                }

                &:nth-child(4n) {
                    margin-right: 0;
                }
            }
        }
    }

    .van-button {
        padding: 0 150px;
        font-size: 30px;
        height: 75px;
        background-color: #000;
        border-color: #000;
        margin-top: 40px;
    }

    &.van-popup-invite {
        height: 100vh;
        padding: 0;

        .invite_title {
            width: 100%;
            height: 80px;
            display: flex;
            font-size: 33px;
            align-items: center;
            justify-content: center;
            position: relative;

            .invite_img {
                position: absolute;
                left: 20px;
                width: 50px;
                height: 50px;
                background-color: rgba(220, 220, 220, 0.5);
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    height: 80%;
                    width: 80%;
                    margin-left: -10px;
                    opacity: 0.6;
                }
            }
        }

        .invite_wrap {
            height: 94vh;
            background-color: #f2f3f7;
            padding: 10px;

            .invite_main {
                width: 700px;
                margin: 10px auto;
                background: #fff;
                padding: 20px;
                border-radius: 24px;
            }

            .main_item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 85px;
                border-bottom: 1px solid #f2f3f7;

                .invite_sub {
                    display: flex;
                    align-items: center;

                    img {
                        width: 40px;
                        height: 40px;
                    }
                }

                .invite_sub_title {
                    font-size: 30px;
                    color: #262a33;
                    font-weight: 700;
                    margin-left: 10px;
                }

                .invite_des {
                    flex: 1;
                    color: #ff6685;
                    font-size: 24px;
                    margin-left: 20px;
                }
            }

            .inp_title {
                font-size: 30px;
                color: #262a33;
            }

            .inp {
                flex: 1;
                line-height: 50px;
                padding: 0 0 0 30px;
                font-size: 30px;
                color: #262a33;
            }

            .inp_info {
                width: 60px;
                font-size: 26px;
                color: #262a33;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin-left: 10px;
            }

            .radio {
                transform: scale(0.6);
            }

            .btn {
                height: 80px;
                width: 400px;
                margin: 500px auto 0;
                border-radius: 40px;
                background: #1a1f29;
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 30px;
            }

            .no_remember {
                height: 30px;
                width: 30px;
                box-sizing: border-box;
                border: 1px solid #000;
                border-radius: 50%;
            }

            .remember {
                height: 30px;
                width: 30px;
                box-sizing: border-box;
                border: 10px solid #ff6685;
                border-radius: 50%;
            }

            .tip-container {
                width: 700px;
                margin: 0 auto;
                background-color: #fff;
                border-radius: 24px;
                color: #ff704d;
                font-size: 26px;
                padding: 30px 20px;
                line-height: 40px;
                text-align: left;

                .van-icon {
                    font-size: 30px;
                    vertical-align: sub;
                }
            }
        }
    }
}
</style>
