<template>
  <div>
    <div class="order_container title">
      <div class="order_content" @click="toOrderList('0')">
        <div class="order_all">全部订单</div>
        <div class="detail">查看
          <van-icon name="arrow" style="font-size: 24px;color: #aaa;"/>
        </div>
      </div>
    </div>

    <div class="order_container">
      <div class="order_contentdiv">
        <div class="order-subtitle">进行中</div>
        <div class="order_itemdiv">
          <div class="order_item" v-for="(item,index) in online" :key="index" @click="toOrderList(item.type)">
            <img class="order_icon" :src="order_icon[item.type]"/>
            <div class="order_item_title">{{item.title}}</div>
            <div v-if="item.num"  class="order_item_subtitle">{{item.num}}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="order_container">
      <div class="order_contentdiv">
        <div class="order-subtitle">特殊订单</div>
        <div class="order_itemdiv">
          <div class="order_item" v-for="(item,index) in spec" :key="index" @click="toOrderList(item.type)">
            <img class="order_icon" :src="order_icon[item.type]"/>
            <div class="order_item_title">{{item.title}}</div>
            <div v-if="item.num"  class="order_item_subtitle">{{item.num}}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="order_container">
      <div class="order_contentdiv">
        <div class="order-subtitle">已完结</div>
        <div class="order_itemdiv">
          <div class="order_item" v-for="(item,index) in finish" :key="index" @click="toOrderList(item.type)">
            <img class="order_icon" :src="order_icon[item.type]"/>
            <div class="order_item_title">{{item.title}}</div>
            <div v-if="item.num" class="order_item_subtitle">{{item.num}}</div>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
  import axios from "axios";
  import qs from "qs";
  export default {
    name: "OrderTab",
    data(){
      return {
        finish:[],
        online:[],
        spec:[],
        order_icon:{
          1:"https://img.jimeimadou.com/common/resource/order_icon/order_dpd_icon.png",
          18:"https://img.jimeimadou.com/common/resource/order_icon/order_dgt_icon.png",
          3:"https://img.jimeimadou.com/common/resource/order_icon/order_dfh_icon.png",
          4:"https://img.jimeimadou.com/common/resource/order_icon/order_sh_icon.png",
          5:"https://img.jimeimadou.com/common/resource/order_icon/order_dps_icon.png",
          6:"https://img.jimeimadou.com/common/resource/order_icon/order_sh_icon.png",
          7:"https://img.jimeimadou.com/common/resource/order_icon/order_shjj_icon.png",
          8:"https://img.jimeimadou.com/common/resource/order_icon/order_jh_icon.png",
          9:"https://img.jimeimadou.com/common/resource/order_icon/order_dwc_icon.png",
          40:"https://img.jimeimadou.com/common/resource/order_icon/order_jf_icon.png",
          17:"https://img.jimeimadou.com/common/resource/order_icon/order_qxz_icon.png",
          15:"https://img.jimeimadou.com/common/resource/order_icon/order_byq_icon.png",
          100:"https://img.jimeimadou.com/common/resource/order_icon/order_ly_icon.png",
          10:"https://img.jimeimadou.com/common/resource/order_icon/order_ywc_icon.png",
          20:"https://img.jimeimadou.com/common/resource/order_icon/order_yqx_icon.png",
          30:"https://img.jimeimadou.com/common/resource/order_icon/order_bjj_icon.png",
        }
      }
    },
    methods:{
      getOrderTypes(){
        let initparams = {
          "access_token": localStorage.getItem("access_token")
        };
        let params = this.sign_module.signParams(initparams);
        params["_platform"] = "mobi";
        params["_version"] = "1.0.0";
        axios.post(
          process.env.VUE_APP_URL + "/mobi/order/myv2",
          qs.stringify(params), {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        ).then((res) => {
            if (res.data.status == 'y') {
              let result = JSON.parse(res.data.data);
              //console.log("所有订单状态列表",result);
              this.finish = result.finish;
              this.online = result.online;
              this.spec = result.spec;
            }
        })
      },

      toOrderList(type){
        //console.log(type);
        //this.$toast('请搜索“灰豚通告plus”小程序去查看订单！')
        if(type){
          this.$router.push({name: 'orderlist', query:{type: type}})
        }
      }
    },
    activated() {
      this.getOrderTypes()
    }
  }
</script>

<style lang="scss" scoped>
  /* pages/order/order.wxss */
  .order_container {
    width: 100%;
    padding: 10px 24px;
    position: relative;
    &.title {
      padding-top: 30px;
    }
  }

  .order_content {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 20px;
    padding: 0 30px;
    line-height: 100px;
    .order_all {
      font-size: 32px;
      font-weight: 600;
    }
    .detail {
      font-size: 26px;
      color: #aaa;
      display: flex;
      align-items: center;
    }
  }

  .order_contentdiv {
    background-color: #fff;
    border-radius: 20px;
    padding: 20px 0;
  }

  .content {
    height: 100vh;
    width: 100%;
    position: absolute;
    z-index: 100;
  }

  .order_imageview {
    width: 100%;
    position: fixed;
    z-index: -100;
    bottom: 17px;
    display: flex;
    justify-content: center;
  }

  .order_image {
    width: 450px;
    height: 300px;
  }

  .order_contentview {
    width: 100%;
    background-color: white;
    border-radius: 24px;
    padding: 20px 0;
  }

  .order_title {
    height: 33px;
    font-size: 24px;
    color: #8A8E99;
    line-height: 33px;
  }

  .order_itemview {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .order_itemdiv {
    display: flex;
    flex-wrap: wrap;
  }

  .order_item {
    width: 20%;
    height: 125px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    justify-content: center;
  }

  .order-subtitle {
    padding: 0 30px;
    font-size: 30px;
    font-weight: 700;
    line-height: 60px;
    color: #000;
  }

  .order_icon {
    width: 46px;
    height: 46px;
    display: block;
  }

  .order_item_title {
    margin-top: 15px;
    height: 33px;
    font-size: 24px;
    color: #262A33;
    line-height: 33px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
  }

  .order_item_subtitle {
    position: absolute;
    height: 24px;
    padding: 0 8px;
    border-radius: 24px;
    line-height: 24px;
    color: white;
    font-size: 20px;
    background-color: #FF3333;
    top: 5px;
    left: 80px;
  }

</style>