<template>
	<div class="container-wrapper">
		<div class="order-title">
			<div class="back_img">
				<img class="back_img"
					src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/normal_icon/Back%403x.png"
					@click="back_list" alt />
			</div>
			上传报告
		</div>
		<div class="upload-wrap">
			<div class="top-title">选择图片或视频</div>
			<div class="sub-title">请按照通告要求上传对应的照片或者视频。（严禁上传淘宝评价截图）</div>

      <div class="upload-container">
        <van-uploader
            class="upload-item-con"
            multiple
            :after-read="afterReadImage"
            :previewImage="false"
            accept="image/*">
<!--        <div class="upload-item-con" @click="handleSendImageClick">-->
          <div class="upload-item">
            <img class="upload-icon"
                 src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/edit_album/upload_image%403x.png"/>
            <div class="upload-text">上传图片</div>
          </div>
<!--        </div>-->
        </van-uploader>

				<div class="upload-item-con" v-for="(item,index) in image_path_arr" :key="index">
					<div class="upload-item">
						<img class="upload-image" :src="item"/>
						<van-icon class="cuIcon-close" name="cross" @click="deleteImage(index)" />
					</div>
				</div>
			</div>

      <div class="upload-container">
        <van-uploader
            class="upload-item-con"
            multiple
            :after-read="afterReadVideo"
            accept="video/*"
            :previewImage="false">
          <div class="upload-item">
            <img class="upload-icon"
                 src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/edit_album/upload_cao%403x.png"/>
            <div class="upload-text">上传视频</div>
          </div>
        </van-uploader>

				<div class="upload-item-con" v-if="video_path">
					<div class="upload-item">
						<div class="video_mask">
							<video class="upload-video" :src="video_path"></video>
							<img class="play_icon" src="../../../public/static/play.png" />
						</div>
						<van-icon class="cuIcon-close" name="cross" @click="deleteVideo" />
					</div>
				</div>
			</div>

			<div class="submit-btn" @click="commitMessage">确认</div>

		</div>
	</div>
</template>

<script>
	import {
		uploadFile
	} from '../../util/uploadBaogao'
	import axios from "axios";
	import qs from "qs";

  export default {
    name: "OrderCommit",
    data() {
      return {
        fromPage: null,
        order_id: null,
        task_require: 0,
        video_path: "",
        image_path_arr: [],
        isShow: false,
        min_length: 5,//最少上传5张图片
        current: 0,
      }
    },
    methods: {
      back_list() {
        this.$router.back(-1);
      },

      // handleSendImageClick() {
      //   this.$refs.imagePicker.click();
      // },

      //选择图片
      afterReadImage(files) {
        let file = [];
        if (!Array.isArray(files)) {
          file.push(files)
        } else {
          file = files;
        }
        this.$toast.loading({
          message: "上传中",
          duration: 0,
        });
        for (let i = 0; i < file.length; i++) {
          uploadFile(file[i].file, "user/img/photo_album").then(res => {
						if(res.status === 'fail'){
							this.$toast.clear();
							this.$toast('上传失败，请重新上传');
							return;
						}
            if (res.url) {
              this.image_path_arr.push(res.url);
            }
            if(this.image_path_arr.length >= file.length){
              this.$toast.clear();
            }
          });
        }
      },

			//删除图片
			deleteImage(index) {
				this.image_path_arr.splice(index, 1);
			},

      //选择视频
      afterReadVideo(files) {
        let file = [];
        if (!Array.isArray(files)) {
          file.push(files)
        } else {
          file = files;
        }
        this.$toast.loading({
          message: "上传中",
          duration: 0,
        });
        for (let i = 0; i < file.length; i++) {
          uploadFile(file[i].file, "user/img/photo_album", "mp4").then(res => {
          	if(res.status === 'fail'){
							this.$toast.clear();
							this.$toast('上传失败，请重新上传');
          		return;
						}
            if (res.url) {
              this.video_path = res.url;
              this.$toast.clear();
            }
          })
        }
      },

			//删除视频
			deleteVideo() {
				this.video_path = ""
			},

			//提交报告
			commitMessage() {

				if (!this.image_path_arr.length && !this.video_path) {
					this.$toast('请先选择要上传的文件');
					return
				}
				if (this.task_require != 2 && this.image_path_arr.length < this.min_length) {
					this.$toast('最少上传' + this.min_length + '张图片');
					return
				}
				this.sureCommit();
			},

			sureCommit() {
				let photos = this.image_path_arr;
				let initparams = {
					order_id: this.order_id,
					photos: photos.join(';'),
					videos: this.video_path,
					access_token: localStorage.getItem("access_token")
				};
				console.log("提交报告请求参数", initparams);
				let params = this.sign_module.signParams(initparams);
				params["_platform"] = "mobi";
				params["_version"] = "1.0.0";
				axios.post(
					process.env.VUE_APP_URL + "/mobi/order/commitbaogao",
					qs.stringify(params), {
						headers: {
							"Content-Type": "application/x-www-form-urlencoded",
						},
					}
				).then((res) => {
					if (res.data.status == 'y') {
						this.$toast('提交成功');

						this.image_path_arr = [];
						this.video_path = "";
            // 如果是订单列表页进入，提交完则需要刷新上个页面（订单列表页）
            // 订单详情页进入，提交完需要刷新上个页面（订单详情页）和上上个页面（订单列表页）
            if (this.fromPage) {
              // 刷新上个页面
              if (this.fromPage == "orderDetail") {
                // 刷新上上个页面（订单列表）
								this.$router.replace({
									name: "orderdetail",
									query:{
										page: 'detail'
									}
								})
              }
              if(this.fromPage == "orderList") {
                this.$router.replace({
                  name: "orderlist",
                  query:{
                    type: this.current,
										page: 'list'
                  }
                })
              }
            }
            //this.back_list();
          } else {
            this.$toast(res.data.error);
          }
        })
      }
    },
    created() {
      if (this.$route.query.id) {
        this.order_id = this.$route.query.id;
      }
      if (this.$route.query.type) {
        this.task_require = this.$route.query.type;
      }
      if (this.$route.query.fromPage) {
        this.fromPage = this.$route.query.fromPage;
      }
      if (this.$route.query.current) {
        this.current = this.$route.query.current;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .commit_baogao ,.commit_baogao_detail{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 1;
  }

	@keyframes slide-in {
		0% {
			transform: translateX(100%);
		}

		100% {
			transform: translateX(0);
		}
	}

	@keyframes slide-out {
		0% {
			transform: translateX(0);
		}

		100% {
			transform: translateX(100%);
		}
	}

	.slide_in {
		animation: slide-in 300ms ease-in;
	}

	.slide_out {
		animation: slide-out 300ms ease-in;
	}

	.container-wrapper {
		position: fixed;
		width: 100vw;
		height: 100vh;
		background-color: #fff;

		.order-title {
			width: 100%;
			height: 80px;
			display: flex;
			font-size: 35px;
			align-items: center;
			justify-content: center;
			position: relative;
			padding: 20px;
			background: #fff;

			.back_img {
				position: absolute;
				left: 20px;
				width: 50px;
				height: 50px;
				background-color: rgba(220, 220, 220, 0.5);
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					height: 80%;
					width: 80%;
					margin-left: -10px;
					opacity: 0.6;
				}
			}
		}

		.upload-wrap {
			padding: 20px;
			background-color: #fafafa;
			height: 100vh;

			.top-title {
				line-height: 50px;
				font-size: 32px;
			}

			.sub-title {
				line-height: 35px;
				font-size: 24px;
				color: #8c8c8c;
				margin-bottom: 10px;
			}

			.upload-container {
				padding: 5px 0;
				display: flex;
				flex-wrap: wrap;
			}

			.upload-item-con {
				width: 23%;
				height: 160px;
				background-color: #f1f1f1;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 5px;
				overflow: hidden;
				margin-right: 10px;
				margin-bottom: 10px;

				.upload-item {
					text-align: center;
					width: 100%;
					height: 100%;
					position: relative;

					.upload-icon {
						width: 40px;
						height: 40px;
						margin-bottom: 10px;
					}

					.upload-text {
						color: #aaa;
						font-size: 24px;
					}

					.upload-image {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}

					.cuIcon-close {
						position: absolute;
						right: 0;
						top: 0;
						width: 40px;
						height: 40px;
						line-height: 40px;
						text-align: center;
						background-color: rgba(0, 0, 0, 0.8);
						color: #fff;
						border-radius: 0 5px 0 5px;
					}

					.video_mask {
						background: #000;
						display: flex;
						justify-content: center;
						align-items: center;
						width: 100%;
						height: 100%;
						position: relative;

						.upload-video {
							object-fit: contain;
							max-width: 100%;
							max-height: 100%;
						}

						.play_icon {
							width: 40px;
							height: auto;
							position: absolute;
						}
					}
				}

				&:nth-child(4n) {
					margin-right: 0;
				}
			}

			.submit-btn {
				line-height: 80px;
				border-radius: 40px;
				width: 400px;
				text-align: center;
				background-color: #000;
				color: #fff;
				font-size: 30px;
				position: fixed;
				bottom: 150px;
				left: 50%;
				transform: translateX(-50%);
			}

		}
	}
</style>
