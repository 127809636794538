import axios from "axios";
import qs from "qs";
import VanImagePre from "vant/lib/image-preview";
import {formatTime} from "../../../util/utils"

export default {
  data() {
    return {
      fromPage: null,
      detail: {},
      is_show_usermsg: false,
      is_show_jh: false, //寄回
      is_show_jc: false, //寄出
      is_show_flow: false,
      show_photo: false,
      orderStatus: {
        1: "待派单",
        2: "被拒绝",
        3: "待发货",
        4: "待收货",
        5: "待拍摄",
        6: "待审核",
        7: "审核拒绝",
        8: "待寄回",
        9: "待完成",
        10: "已完成",
        11: "已取消",
        12: "已取消",
        13: "已取消",
        14: "已取消",
        15: "被邀请",
        16: "邀请被拒绝",
        17: "取消中",
        18: "待沟通",
      },
      mdTypes: this.mdTypes,
      xhsType: this.xhsType,
      sexType: this.sexType,
      promType: this.promType,
      scriptType: {
        "1": "商家提供",
        "2": "达人编写"
      },
      channels: this.channels,
      fieldShow: false,
      field_show_arr: [],
      tagList: [],
      tagShow: false,
      tag_show_arr: [],
      tag_maxshow_num: 5,//最多展示几个标签，其余隐藏
      message: "",
      saveTimeTip: false,
      taskDetail: {},
      note_link: "",
      showXhsNote: false,
      showExpress: false,
      expressno: "",
      expressList: [],
      current: -1,
      expressType: '',
      showXhsUrl: false,
      xhs_url: ""
    }
  },
  methods: {
    back_list() {
      this.$router.back(-1);
    },

    //复制
    copyContent(content, type) {
      this.$copyText(content).then(() => {
          let msg = "复制成功";
          if (type === 'url') {
            msg = "复制链接成功";
          } else if (type === 'link') {
            msg = "复制口令成功";
          }
          this.$toast(msg);
        }, () => {
          this.$toast('复制失败');
        }
      )
    },

    //请求详情数据
    ht_load_detail() {
      const that = this;
      const initparams = {
        access_token: localStorage.getItem('access_token'),
        order_id: this.orderId
      };
      let params = this.sign_module.signParams(initparams);
      params["_platform"] = "mobi";
      params["_version"] = "1.0.0";
      axios.post(
        process.env.VUE_APP_URL + "/mobi/order/detail",
        qs.stringify(params), {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      ).then((res) => {
        if (res.data.status == 'y') {
          var data = JSON.parse(res.data.data);
          console.log('orderdetail .... : ', data);

          let orderStatus = data.order_status;
          if ((orderStatus != 1 && orderStatus != 2 && orderStatus != 11 && orderStatus != 12 && orderStatus != 13 && orderStatus != 14 && orderStatus != 15) && data.wechat && data.connect_type == 1) {
            data.showWechat = true;
          }
          if (orderStatus == 1 || orderStatus == 15) {
            this.getTaskDetail(data.task_id);
          }

          data.goods_pic = data.goods_pic.split(",")[0];

          //卖家物流
          if (data.seller_express.express_info) {
            data.seller_express.express_info = JSON.parse(data.seller_express.express_info);
          }
          //模特寄回物流
          if (data.mt_express.mt_express_info) {
            data.mt_express.mt_express_info = JSON.parse(data.mt_express.mt_express_info);
          }

          // 处理倒计时时间
          this.handleCountDown(data);

          let mediaurl = [];
          //报告视频
          if (data.baogao_info.videos) {
            data.baogao_info.videos = data.baogao_info.videos.split(';');
            const videourl = data.baogao_info.videos;
            for (let i = 0; i < videourl.length; i++) {
              mediaurl.push({
                url: videourl[i],
                type: 'video'
              })
            }
          }
          //审核报告图片
          if (data.baogao_info.photos) {
            data.baogao_info.photos = data.baogao_info.photos.split(';');
            const imgurl = data.baogao_info.photos;
            for (let i = 0; i < imgurl.length; i++) {
              /**
               * 这边后台返回了拼接了水印导致有时候图片链接有时候预览不了的问题，所以替换成了oss域名
               * 至于为什么部分图片https://img.jimeimadou.com域名拼接水印预览不了，原因未知
               */
              imgurl[i] = imgurl[i].replace('https://img.jimeimadou.com',
                'https://jmstatic-1255497916.cos-website.ap-shanghai.myqcloud.com'
              );
              mediaurl.push({
                url: imgurl[i],
                type: 'image'
              })
            }
          }
          if(data.baogao_info.update_time){
            data.baogao_info.update_time = formatTime(data.baogao_info.update_time);
          }

          that.mediaurl = mediaurl;
          that.detail = data;
          that.show_bottom_inp = false;
          that.message = '';
          that.skeletonShow = false;
          that.xhs_save_time = data.xhs_forever_save == 1 ? '永久' : (data.xhs_save_time + '个月');
        }
      })
    },

    //获取通告详情
    getTaskDetail(id) {
      const initparams = {
        access_token: localStorage.getItem('access_token'),
        task_id: id
      };
      this.isLoading = true;
      let params = this.sign_module.signParams(initparams);
      params["_platform"] = "mobi";
      params["_version"] = "1.0.0";
      axios.post(
        process.env.VUE_APP_URL + "/mobi/task/detail",
        qs.stringify(params), {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      ).then(res => {
        if (res.data.status == 'y') {
          let data = JSON.parse(res.data.data);
          console.log("通告详情====", data);

          data.mote_type = data.mote_type ? parseInt(data.mote_type) : 0;
          data.no_cityArr = data.no_cityArr ? data.no_cityArr.toString().split(',').join(' ') : '无';
          if (data.tag_names) {
            let fieldList = data.tag_names.split(",");

            this.fieldList = fieldList;
            this.field_show_arr = fieldList.length > this.tag_maxshow_num ? fieldList.slice(0, this.tag_maxshow_num) : fieldList;
            this.fieldShow = fieldList.length > this.tag_maxshow_num;
          }
          if (data.spec_names) {
            let tagList = data.spec_names.split(",");
            this.tagList = tagList;
            this.tag_show_arr = tagList.length > this.tag_maxshow_num ? tagList.slice(0, this.tag_maxshow_num) : tagList;
            this.tagShow = tagList.length > this.tag_maxshow_num
          }
          //示例照片
          if (data.photo_sample) {
            data.photo_sample = data.photo_sample.split(',')
          }
          //示例视频
          if (data.video_sample) {
            data.video_sample = data.video_sample.split(',')
          }
          this.taskDetail = data;
          this.isLoading = false;
        }
      })
    },

    //倒计时
    handleCountDown(data) {
      let end_time = 0;
      switch (parseInt(data.order_status)) {
        case 4:
          //待收货订单（10天）
          end_time = parseInt(data.update_time) + 10 * 24 * 60 * 60;
          data.countdown_time = this.getCountDownTime(end_time, data.order_status);
          break;
        case 5:
          //待拍摄订单（承诺时间内）
          end_time = parseInt(data.update_time) + parseInt(data.promise_commit_time) * 60 * 60;
          data.countdown_time = this.getCountDownTime(end_time, data.order_status);
          break;
        case 8:
          //待寄回订单（48小时）
          end_time = parseInt(data.update_time) + 48 * 60 * 60;
          data.countdown_time = this.getCountDownTime(end_time, data.order_status);
          break;
        case 15:
          //被邀请（响应时间内）
          end_time = parseInt(data.update_time) + parseInt(data.promise_commit_time) * 60 * 60;
          data.countdown_time = this.getCountDownTime(end_time, data.order_status);
          break;
        case 18:
          //待沟通订单（响应时间内）
          end_time = data.connect_over_time;
          data.countdown_time = this.getCountDownTime(end_time, data.order_status);
          break;
        default:
          break;
      }
    },

    getCountDownTime(end_time, status) {
      let nowTime = new Date().getTime() / 1000;
      let second_time = end_time - nowTime;
      let newTime = [];
      if (second_time <= 0) {
        newTime = "(已超时)"
      } else if (second_time >= 60) {
        let day = parseInt(second_time / 60 / 60 / 24);
        let hour = parseInt((second_time - day * 24 * 60 * 60) / 60 / 60);
        let minute = parseInt((second_time - day * 24 * 60 * 60 - hour * 60 * 60) / 60);
        if (day) {
          // padStart方法属于es6的方法，低版本微信不支持，会报错
          // newTime.push(day.toString().padStart(2, '0') + '天')
          day = day.toString();
          newTime.push((day.length < 2 ? '0' + day : day) + '天')
        }
        if (hour) {
          // newTime.push(hour.toString().padStart(2, '0') + '小时')
          hour = hour.toString();
          newTime.push((hour.length < 2 ? '0' + hour : hour) + '小时')
        }
        if (minute && !day) {
          // newTime.push(minute.toString().padStart(2, '0') + '分钟')
          minute = minute.toString();
          newTime.push((minute.length < 2 ? '0' + minute : minute) + '分钟')
        }
        newTime = newTime.join("")
      } else {
        newTime = "01分钟"
      }
      switch (parseInt(status)) {
        case 4:
          //待收货
          newTime = `收货倒计时：${newTime}`;
          break;
        case 5:
          //待拍摄
          newTime = `上传倒计时：${newTime}`;
          break;
        case 8:
          //待寄回
          newTime = `寄回倒计时：${newTime}`;
          break;
        case 15:
          //被邀请
          newTime = `响应倒计时：${newTime}`;
          break;
        case 18:
          //待沟通
          newTime = `响应倒计时：${newTime}`;
          break;
        default:
          break;
      }
      return newTime
    },

    //上传留言
    upload_msg() {
      var that = this;
      if (!this.message || !this.message.trim()) {
        return
      }
      var initparams = {
        access_token: localStorage.getItem('access_token'),
        order_id: this.orderId,
        content: this.message
      };
      let params = this.sign_module.signParams(initparams);
      params["_platform"] = "mobi";
      params["_version"] = "1.0.0";
      axios.post(
        process.env.VUE_APP_URL + "/mobi/order/appendmsg",
        qs.stringify(params), {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      ).then((res) => {
        if (res.data.status == 'y') {
          this.$toast('添加留言成功');
          //重新加载数据
          that.ht_load_detail();
        }
      })
    },

    order_logistics_more(click_type) {
      if (click_type == 0) {
        //留言
        this.is_show_usermsg = true
      } else if (click_type == 1) {
        //寄回来
        this.is_show_jh = true
      } else if (click_type == 2) {
        //寄出去
        this.is_show_jc = true
      } else {
        //流程
        this.is_show_flow = true
      }
    },

    //弹窗
    showModel(type) {
      if (type === 'xhs') {
        this.showXhsNote = true;
      }
    },

    //提交笔记链接
    commitNoteLink() {
      let orderInfo = this.detail;
      if (!orderInfo) {
        return;
      }
      if (!this.note_link) {
        this.$toast('请输入已发布笔记链接');
        return
      }
      const initparams = {
        access_token: localStorage.getItem("access_token"),
        order_id: orderInfo.order_id,
        xhs_note_str: this.note_link
      };
      let params = this.sign_module.signParams(initparams);
      params["_platform"] = "mobi";
      params["_version"] = "1.0.0";
      axios.post(
        process.env.VUE_APP_URL + "/mobi/order/commitbaogao",
        qs.stringify(params), {headers: {"Content-Type": "application/x-www-form-urlencoded",},})
        .then((res) => {
          if (res.data.status == "n") {
            this.$toast(res.data.error);
            return
          }
          this.$toast('提交成功');
          this.hideModel();
          this.ht_load_detail();
        })
    },

    chooseCompany(index) {
      this.current = index;
    },
    //保存物流信息
    expressSave() {
      if(!this.expressno){
        this.$toast("请填写物流单号");
        return
      }
      if(this.current<0){
        this.$toast("请选择快递公司");
        return
      }
      let orderInfo = this.detail;
      if (!orderInfo) {
        return
      }
      let courier_sn = this.expressno;
      let kd_no = this.expressList[this.current].kd_no;
      let initparams = {
        access_token: localStorage.getItem('access_token'),
        order_id: orderInfo.order_id,
        courier_sn,
        kd_no,
      };
      if(this.expressType == 2){
        initparams.wuliu_id = orderInfo.seller_express_id;
        initparams.wuliu_type = 2;
      }else{
        initparams.wuliu_id = orderInfo.mt_express_id;
        initparams.wuliu_type = 1;
      }
      let params = this.sign_module.signParams(initparams);
      params["_platform"] = "mobi";
      params["_version"] = "1.0.0";
      axios.post(
        process.env.VUE_APP_URL + "/mobi/order/updateorderwuliu",
        qs.stringify(params), {headers: {"Content-Type": "application/x-www-form-urlencoded",},})
        .then((res) => {
          if (res.data.status == "n") {
            this.$toast(res.data.error);
            return
          }
          this.$toast('上传成功');
          this.expressno = "";
          this.current = -1;
          this.hideModel();
          this.ht_load_detail();
        })
    },

    hideModel(){
      this.showXhsNote = false;
      this.note_link = "";
      this.showExpress = false;
    },

    onBeforeClose(action, done) {
      if (action === 'confirm') {
        return done(false);
      } else {
        return done();
      }
    },

    to_goods_detail() {
    },

    async toChat() {
      let seller_id = this.detail.seller_id;
      let order_id = this.detail.order_id;
      let nick = this.detail.seller_nick;
      //订单上报
      await this.recordOrder(seller_id, order_id);
      let account = 'seller_' + seller_id;
      // 更新会话对象
      this.$store.dispatch({
        type: 'CurrentChatTo_Change',
        payload: account
      });
      //获取当前点击的会话对象的聊天信息
      this.getMessages(account);
      this.$router.push({
        name: 'chat',
        query:{
          chatTo: account,
          nick: nick
        }
      });
    },
    //订单上报
    recordOrder(seller_id, order_id) {
      return new Promise(resolve => {
        let initparams = {
          order_id,
          seller_id,
          access_token: localStorage.getItem('access_token')
        };
        let params = this.$addParams(initparams);
        this.$axios.post(
          process.env.VUE_APP_URL + "/mobi/quickmessage/recordorder",
          this.$qs.stringify(params), {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        ).then((res) => {
          if (res.data.status == 'y') {
            console.log("订单上报成功");
            resolve()
          } else {
            this.$toast(res.data.error);
          }
        })
      })
    },
    getMessages(id) {
      if (!this.$store.state.nim) {
        return
      }
      let self = this;
      this.$store.state.nim.getHistoryMsgs({
        scene: "p2p",
        to: id,
        limit: 10,
        asc: true,
        done: (err, data) => {
          if (err) {
            console.log(err);
            return
          }
          console.log(data);
          //更新聊天信息列表
          self.$store.dispatch('SetCurrentChatMessage',data.msgs)
        }
      })
    },

    show_change(type) {
      this.expressType = type;
      this.showExpress = true;
    },

    upload_baogao() {
      let order_id = this.detail.order_id;
      let type = this.detail.task_require;
      this.$router.push({
        name: 'commit',
        query: {
          id: order_id,
          type,
          fromPage: 'orderDetail'
        }
      })
    },

    show_image(index) {
      VanImagePre({
        images: this.taskDetail.photo_sample,
        startPosition: index,
      });
    },
    show_baogao(index) {
      VanImagePre({
        images: this.detail.baogao_info.photos,
        startPosition: index,
      });
    },

    show_video(album) {
      this.$router.push({
        path: "/main/album",
        query: {
          type: 1,
          album: JSON.stringify({videos: album}),
        },
      });
    },

    toWebdiv() {
    },

    detial_show_media() {
    },

    //展示所有内容标签
    showAllFieldTag() {
      this.field_show_arr = this.fieldList;
      this.fieldShow = false;
    },

    //展示所有特色标签
    showAllSpecTag() {
      this.tag_show_arr = this.tagList;
      this.tagShow = false;
    },

    showTip() {
      this.saveTimeTip = !this.saveTimeTip;
    },

    toCourse(){
      let url = "https://img.jimeimadou.com/user/img/auth_course/note_jc_xhs.png";
      this.showXhsUrl = true;
      this.xhs_url = url;
    },

    show_bottom() {
    },

    fetchExpressList() {
      let initparams = {
        access_token: localStorage.getItem("access_token"),
      };
      let params = this.sign_module.signParams(initparams);
      params["_platform"] = "mobi";
      params["_version"] = "1.0.0";
      axios.post(
        process.env.VUE_APP_URL + "/mobi/order/wuliucompany",
        qs.stringify(params), {headers: {"Content-Type": "application/x-www-form-urlencoded",},})
        .then((res) => {
          if (res.data.status == "n") {
            this.$toast(res.data.error);
            return;
          }
          this.expressList = JSON.parse(res.data.data);
        })
    }
  },
  activated() {

  },
  deactivated() {
    this.is_show_usermsg = false;
    this.is_show_jh = false;
    this.is_show_jc = false;
    this.is_show_flow = false;
  },
  beforeRouteEnter(e, r, next) {
    next(vm => {
      if (r.name === 'commit' && vm.$route.query.page === 'detail') {
        vm.detail = null;
        vm.taskDetail = null;
        if (vm.$route.query.id) {
          vm.orderId = vm.$route.query.id;
        }
        if (vm.$route.query.fromPage) {
          vm.fromPage = vm.$route.query.fromPage;
        }
        vm.ht_load_detail();
        vm.fetchExpressList();
      }else if(r.name !== 'album' && r.name !== 'commit'){
        vm.detail = null;
        vm.taskDetail = null;
        if (vm.$route.query.id) {
          vm.orderId = vm.$route.query.id;
        }
        if (vm.$route.query.fromPage) {
          vm.fromPage = vm.$route.query.fromPage;
        }
        vm.ht_load_detail();
        vm.fetchExpressList();
      }

    });
  }

}