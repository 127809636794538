import axios from "axios";
import qs from "qs";
import {uploadFile} from '../../../util/uploadBaogao'

export default {
  data() {
    return {
      orderStatus: {
        1: "待派单",
        2: "被拒绝",
        3: "待发货",
        4: "待收货",
        5: "待拍摄",
        6: "待审核",
        7: "审核拒绝",
        8: "待寄回",
        9: "待完成",
        10: "已完成",
        11: "已取消",
        12: "已取消",
        13: "已取消",
        14: "已取消",
        15: "被邀请",
        16: "邀请被拒绝",
        17: "取消中",
        18: "待沟通",
      },
      navBar: [
        {
          title: '最新',
          type: 0
        },
        {
          title: '待派单',
          type: 1
        },
        {
          title: '待沟通',
          type: 18,
          tip: "若未在响应时间内确认沟通，任务将自动取消",
          showTip: true
        },
        {
          title: '待发货',
          type: 3
        },
        {
          title: '待收货',
          type: 4
        },
        {
          title: '待拍摄',
          type: 5
        },
        {
          title: '待审核',
          type: 6
        },
        {
          title: '审核拒绝',
          type: 7
        },
        {
          title: '待寄回',
          type: 8
        },
        {
          title: '待完成',
          type: 9
        },
        {
          title: '纠纷',
          type: 40,
          tip: "请添加商家微信或留言解决纠纷，否则无法报名通告",
          showTip: true
        },
        {
          title: '取消中',
          type: 17,
          tip: "商家若未在72小时内响应取消申请，将自动取消",
          showTip: true
        },
        {
          title: '被邀请',
          type: 15
        },
        {
          title: '已完成',
          type: 10
        },
        {
          title: '已取消',
          type: 20
        },
        {
          title: '被拒绝',
          type: 30
        },
      ],
      currentNav: 0,
      page: 1,
      page_size: 10,
      stopLoad: false,
      isNull: false,
      orderList: [],
      keyword: "",
      cancelReason: "",
      refuseReason: "",
      disputeReason: "",
      reportReason: "",
      reportTypes: [
        {
          text: "虚假通告",
          type: 1
        },
        {
          text: "虚假报价",
          type: 2
        },
        {
          text: "虚假要求",
          type: 3
        },
        {
          text: "其它",
          type: 4
        }
      ],
      reportIndex: -1,
      current: -1,
      note_link: "",
      showCancelApply: false,
      orderInfo: null,
      showContact: false,
      showReceive: false,
      showCancelReason: false,
      showDelete: false,
      showCancelOrder: false,
      showReport: false,
      fileList: [],
      showInviteReject: false,
      showExpress: false,
      expressno: '',
      expresskno: '',
      expressList: [],
      showXhsNote: false,
      showDispute: false,
      disputeList: [],
      showCountDown: false,
      isLoading: false,
      showXhsUrl: false,
      xhs_url: "",
      showInvitePopup: false,
      price: 0,
      time: '',
      myScroll: null
    }
  },
  methods: {
    back_list() {
      this.$router.back(-1);
    },

    //刷新重新加载
    list_refresh() {
      this.initData();
      this.getOrderList()
    },

    //初始化列表请求数据
    initData() {
      this.orderList = [];
      this.isNull = false;
      this.stopLoad = false;
      this.page = 1;
    },

    //获取订单列表
    getOrderList() {
      if (this.stopLoad) {
        return
      }
      this.isLoading = true;
      let initparams = {
        page: this.page++,
        order_status: this.currentNav,
        page_size: this.page_size,
        access_token: localStorage.getItem("access_token")
      };
      if (this.keyword) {
        initparams.goods_name = this.keyword.trim();
      }
      let params = this.sign_module.signParams(initparams);
      params["_platform"] = "mobi";
      params["_version"] = "1.0.0";
      axios.post(
        process.env.VUE_APP_URL + "/mobi/order/listv2",
        qs.stringify(params), {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      ).then((res) => {
        this.isLoading = false;
        if (res.data.status == 'n') {
          return
        }
        let result = JSON.parse(res.data.data);
        let orders = result.list;
        console.log(result);

        if (orders.length < 10) {
          this.stopLoad = true;
          if (orders.length == 0 && this.page == 2) {
            this.isNull = true;
          }
        }
        for (let i = 0; i < orders.length; i++) {
          orders[i].goods_pic = orders[i].goods_pic.split(",");
          // 处理倒计时时间
          this.handleCountDown(orders[i]);
          // 待收货订单
          if (orders[i].status == 4 && orders[i].express_info) {
            orders[i].express_info = JSON.parse(orders[i].express_info);
          }
        }
        this.orderList = this.orderList.concat(orders);
      })
    },

    handleCountDown(data) {
      let end_time = 0;
      switch (parseInt(data.status)) {
        case 4:
          //待收货订单（10天）
          end_time = parseInt(data.update_time) + 10 * 24 * 60 * 60;
          data.countdown_time = this.getCountDownTime(end_time, data.status);
          break;
        case 5:
          //待拍摄订单（承诺时间内）
          end_time = parseInt(data.update_time) + parseInt(data.promise_commit_time) * 60 * 60;
          data.countdown_time = this.getCountDownTime(end_time, data.status);
          break;
        case 8:
          //待寄回订单（48小时）
          end_time = parseInt(data.update_time) + 48 * 60 * 60;
          data.countdown_time = this.getCountDownTime(end_time, data.status);
          break;
        case 15:
          //被邀请（响应时间内）
          end_time = parseInt(data.update_time) + parseInt(data.promise_commit_time) * 60 * 60;
          data.countdown_time = this.getCountDownTime(end_time, data.status);
          break;
        case 18:
          //待沟通订单（响应时间内）
          end_time = data.connect_over_time;
          data.countdown_time = this.getCountDownTime(end_time, data.status);
          break;
        default:
          break;
      }
    },

    getCountDownTime(end_time, status) {
      let nowTime = new Date().getTime() / 1000;
      let second_time = end_time - nowTime;
      let newTime = [];
      if (second_time <= 0) {
        newTime = "(已超时)"
      } else if (second_time >= 60) {
        let day = parseInt(second_time / 60 / 60 / 24);
        let hour = parseInt((second_time - day * 24 * 60 * 60) / 60 / 60);
        let minute = parseInt((second_time - day * 24 * 60 * 60 - hour * 60 * 60) / 60);
        if (day) {
          // padStart方法属于es6的方法，低版本微信不支持，会报错
          // newTime.push(day.toString().padStart(2, '0') + '天')
          day = day.toString();
          newTime.push((day.length < 2 ? '0' + day : day) + '天')
        }
        if (hour) {
          // newTime.push(hour.toString().padStart(2, '0') + '小时')
          hour = hour.toString();
          newTime.push((hour.length < 2 ? '0' + hour : hour) + '小时')
        }
        if (minute && !day) {
          // newTime.push(minute.toString().padStart(2, '0') + '分钟')
          minute = minute.toString();
          newTime.push((minute.length < 2 ? '0' + minute : minute) + '分钟')
        }
        newTime = newTime.join("")
      } else {
        newTime = "01分钟"
      }
      switch (parseInt(status)) {
        case 4:
          //待收货
          newTime = `收货倒计时：${newTime}`;
          break;
        case 5:
          //待拍摄
          newTime = `上传倒计时：${newTime}`;
          break;
        case 8:
          //待寄回
          newTime = `寄回倒计时：${newTime}`;
          break;
        case 15:
          //被邀请
          newTime = `响应倒计时：${newTime}`;
          break;
        case 18:
          //待沟通
          newTime = `响应倒计时：${newTime}`;
          break;
        default:
          break;
      }
      return newTime
    },

    //切换nav订单状态
    tabSelect() {
      //console.log(e, index, this.currentNav);
      this.$refs.iscroll.scrollToTop();
      this.initData();
      this.getOrderList()
    },

    //隐藏顶部文字提示
    hideTip(index) {
      this.navBar[index].showTip = false;
    },

    copyContent(content) {
      this.$copyText(content).then(() => {
          this.$toast('复制成功');
        }, () => {
          this.$toast('复制失败');
        }
      )
    },

    //选择举报类型
    chooseReportType(index) {
      this.reportIndex = index;
    },

    afterRead(files) {
      let file = [];
      if (!Array.isArray(files)) {
        file.push(files);
      } else {
        file = files;
      }
      this.$toast.loading({
        message: "上传中",
        duration: 0,
      });
      for (let i = 0; i < file.length; i++) {
        uploadFile(file[i].file, "user/img/feedbackImages").then(res => {
          if (res.url) {
            this.fileList.push(res.url);
          }
          if(this.fileList.length >= file.length){
            this.$toast.clear();
          }
        });
      }
    },

    deleteImage(index) {
      this.fileList.splice(index, 1);
    },

    //=============================搜索相关=====================================
    change_show_real() {
      this.keyword = '';
      this.list_refresh()
    },

    search() {
      if (!this.keyword || !this.keyword.trim()) {
        return
      }
      this.list_refresh()
    },

    //=============================订单操作相关=====================================
    //显示确认弹框
    showModel(info, type) {
      this.orderInfo = info;
      if (type === 'cancel') {
        this.showCancelApply = true;
      } else if (type === 'contact') {
        this.showContact = true;
      } else if (type === 'receive') {
        this.showReceive = true;
      } else if (type === 'reason') {
        this.showCancelReason = true;
        this.cancelReason = info.cancel_reason;
      } else if (type === 'delete') {
        this.showDelete = true;
      } else if (type === 'cancelorder') {
        this.showCancelOrder = true;
      } else if (type === 'report') {
        if(this.orderInfo.has_report == 1){
          this.$toast("该订单您已提交过反馈，我们会尽快核实！");
          return;
        }
        this.showReport = true;
      } else if (type === 'reject') {
        this.showInviteReject = true;
      } else if (type === 'xhs') {
        this.showXhsNote = true;
      } else if (type === 'disputereason') {
        this.showCancelReason = true;
        this.disputeReason = info.dispute_reason;
      } else if (type === 'dispute') {
        this.showDispute = true;
      } else if (type === 'countdown') {
        this.showCountDown = true;
      } else if (type === 'invite') {
        this.showInvitePopup = true;
      }
    },

    showPopup(info) {
      this.orderInfo = info;
      this.showExpress = true;
    },

    hideModel() {
      this.showCancelApply = false;
      this.showContact = false;
      this.showReceive = false;
      this.showCancelReason = false;
      this.showDelete = false;
      this.cancelReason = "";
      this.showCancelOrder = false;
      this.showReport = false;
      this.fileList = [];
      this.showInviteReject = false;
      this.showXhsNote = false;
      this.note_link = "";
      this.disputeReason = "";
      this.showDispute = false;
      this.showCountDown = false;
      this.showInvitePopup = false;
      this.time = '';
      this.price = 0;
    },

    closeInvite(){
      this.showInvitePopup = false;
    },

    onBeforeClose(action, done) {
      if (action === 'confirm') {
        return done(false);
      } else {
        return done();
      }
    },

    // 确认取消报名
    cancelApplyHandler() {
      if (this.orderInfo) {
        if (this.showCancelOrder && !this.cancelReason) {
          this.$toast('请输入取消原因');
          this.showCancelOrder = true;
          return;
        }
        this.handleOrder("cancel").then(res => {
          console.log(res);
          if (res.data.status == "n") {
            this.$toast(res.data.error);
            return
          }
          this.$toast('操作成功');
          this.hideModel();
          this.list_refresh();
        })
      }
    },

    // 确认沟通
    contactHandler() {
      let orderInfo = this.orderInfo;
      if (!orderInfo) {
        return
      }
      let initparams = {
        access_token: localStorage.getItem("access_token"),
        order_id: orderInfo.order_id,
      };
      let params = this.sign_module.signParams(initparams);
      params["_platform"] = "mobi";
      params["_version"] = "1.0.0";
      axios.post(
        process.env.VUE_APP_URL + "/mobi/order/confirmcommunicated",
        qs.stringify(params), {headers: {"Content-Type": "application/x-www-form-urlencoded",},})
        .then((res) => {
          if (res.data.status == "n") {
            this.$toast(res.data.error);
            return
          }
          this.$toast('操作成功');
          this.hideModel();
          this.list_refresh();
        })
    },

    // 确认删除
    deleteHandler() {
      let orderInfo = this.orderInfo;
      if (!orderInfo) {
        return
      }
      let initparams = {
        access_token: localStorage.getItem("access_token"),
        order_id: orderInfo.order_id,
      };
      let params = this.sign_module.signParams(initparams);
      params["_platform"] = "mobi";
      params["_version"] = "1.0.0";
      axios.post(
        process.env.VUE_APP_URL + "/mobi/order/del",
        qs.stringify(params), {headers: {"Content-Type": "application/x-www-form-urlencoded",},})
        .then((res) => {
          if (res.data.status == "n") {
            this.$toast(res.data.error);
            return
          }
          this.$toast('删除成功');
          this.hideModel();
          this.list_refresh();
        })
    },

    // 确认收货
    receiveHandler() {
      if (this.orderInfo) {
        this.handleOrder("confirm").then(res => {
          if (res.data.status == "n") {
            this.$toast(res.data.error);
            return
          }
          this.$toast('确认收货成功');
          this.hideModel();
          this.list_refresh();
        })
      }
    },

    //取消报名取消订单、确认收货
    handleOrder(type) {
      const _this = this;
      return new Promise((resolve) => {
        let orderInfo = _this.orderInfo;
        if (!orderInfo) {
          return
        }
        let initparams = {
          access_token: localStorage.getItem("access_token"),
          order_id: orderInfo.order_id,
          act: type
        };
        if (type == "cancel" && _this.cancelReason) {
          initparams.cancel_reason = _this.cancelReason;
        }
        let params = _this.sign_module.signParams(initparams);
        params["_platform"] = "mobi";
        params["_version"] = "1.0.0";
        axios.post(
          process.env.VUE_APP_URL + "/mobi/order/handle",
          qs.stringify(params), {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }).then((res) => {
          resolve(res)
        })
      })
    },

    //拒绝邀请
    refuseInvite() {
      let orderInfo = this.orderInfo;
      if (!orderInfo) {
        return
      }
      let initparams = {
        access_token: localStorage.getItem("access_token"),
        order_id: orderInfo.order_id,
        reason: this.refuseReason
      };
      let params = this.sign_module.signParams(initparams);
      params["_platform"] = "mobi";
      params["_version"] = "1.0.0";
      axios.post(
        process.env.VUE_APP_URL + "/mobi/order/refuseinvite",
        qs.stringify(params), {headers: {"Content-Type": "application/x-www-form-urlencoded",},})
        .then((res) => {
          if (res.data.status == "n") {
            this.$toast(res.data.error);
            return
          }
          this.$toast('操作成功');
          this.refuseReason = "";
          this.hideModel();
          this.list_refresh();
        })
    },

    //同意邀请
    mt_apply_btn() {
      if (this.price < 0 || !this.price) {
        this.$toast('请输入报价');
        return
      }

      if (!this.time) {
        this.$toast('请输入承诺时间');
        return
      }
      const initparams = {
        access_token: localStorage.getItem('access_token'),
        task_id: this.orderInfo.task_id,
        promise_time: this.time,
        mt_price: this.price,
        order_id: this.orderInfo.order_id
      };
      let params = this.sign_module.signParams(initparams);
      params["_platform"] = "mobi";
      params["_version"] = "1.0.0";
      axios.post(
        process.env.VUE_APP_URL + "/mobi/order/agreeinvite",
        qs.stringify(params), {headers: {"Content-Type": "application/x-www-form-urlencoded",},})
        .then((res) => {
          if (res.data.status == 'y') {
            this.$toast('报名成功');
            this.hideModel();
            this.list_refresh();
          } else {
            this.$toast(res.data.error)
          }
        })
    },

    chooseCompany(index) {
      this.current = index;
    },
    //保存物流信息
    expressSave() {

      if(!this.expressno){
        this.$toast("请填写物流单号");
        return
      }
      if(this.current<0){
        this.$toast("请选择快递公司");
        return
      }
      let orderInfo = this.orderInfo;
      if (!orderInfo) {
        return
      }
      let courier_sn = this.expressno;
      let kd_no = this.expressList[this.current].kd_no;
      let initparams = {
        access_token: localStorage.getItem('access_token'),
        order_id: orderInfo.order_id,
        courier_sn,
        kd_no
      };
      let params = this.sign_module.signParams(initparams);
      params["_platform"] = "mobi";
      params["_version"] = "1.0.0";
      axios.post(
        process.env.VUE_APP_URL + "/mobi/order/sendback",
        qs.stringify(params), {headers: {"Content-Type": "application/x-www-form-urlencoded",},})
        .then((res) => {
          if (res.data.status == "n") {
            this.$toast(res.data.error);
            return
          }
          this.$toast('上传成功');
          this.expressno = "";
          this.current = -1;
          this.showExpress = false;
          this.hideModel();
          this.list_refresh();
        })
    },

    //提交笔记链接
    commitNoteLink() {
      let orderInfo = this.orderInfo;
      if (!orderInfo) {
        return;
      }
      if (!this.note_link) {
        this.$toast('请输入已发布笔记链接');
        return
      }
      const initparams = {
        access_token: localStorage.getItem("access_token"),
        order_id: orderInfo.order_id,
        xhs_note_str: this.note_link
      };
      let params = this.sign_module.signParams(initparams);
      params["_platform"] = "mobi";
      params["_version"] = "1.0.0";
      axios.post(
        process.env.VUE_APP_URL + "/mobi/order/commitbaogao",
        qs.stringify(params), {headers: {"Content-Type": "application/x-www-form-urlencoded",},})
        .then((res) => {
          if (res.data.status == "n") {
            this.$toast(res.data.error);
            return
          }
          this.$toast('提交成功');
          this.hideModel();
          this.list_refresh();
        })
    },

    //提交申诉
    commitDispute() {
      let orderInfo = this.orderInfo;
      if (!orderInfo) {
        return;
      }
      if (!this.disputeReason) {
        this.$toast("请填写申诉说明");
        return
      }
      let initparams = {
        order_id: orderInfo.order_id,
        dispute_id: orderInfo.dispute_id,
        user_appeal_reason: this.disputeReason,
        user_appeal_file: this.fileList.join(","),
        access_token: localStorage.getItem('access_token')
      };
      let params = this.sign_module.signParams(initparams);
      params["_platform"] = "mobi";
      params["_version"] = "1.0.0";
      axios.post(
        process.env.VUE_APP_URL + "/mobi/order/appealopt",
        qs.stringify(params), {headers: {"Content-Type": "application/x-www-form-urlencoded",},})
        .then((res) => {
          if (res.data.status == "n") {
            this.$toast(res.data.error);
            return
          }
          this.$toast('提交成功');
          this.fileList = [];
          this.hideModel();
          this.list_refresh();
        })
    },

    //订单举报
    reportOrder() {
      let orderInfo = this.orderInfo;
      if (this.reportIndex == -1) {
        this.$toast("请选择反馈类型");
        return
      }
      if (!this.reportReason) {
        this.$toast("请填写反馈原因");
        return
      }
      if (this.fileList.length == 0) {
        this.$toast('请上传凭证');
        return
      }
      let initparams = {
        type: this.reportTypes[this.reportIndex].type,
        task_id: orderInfo.task_id,
        order_id: orderInfo.order_id,
        reason: this.reportReason,
        attach: this.fileList.join(","),
        access_token: localStorage.getItem('access_token')
      };
      let params = this.sign_module.signParams(initparams);
      params["_platform"] = "mobi";
      params["_version"] = "1.0.0";
      axios.post(
        process.env.VUE_APP_URL + "/mobi/ureport/commit",
        qs.stringify(params), {headers: {"Content-Type": "application/x-www-form-urlencoded"}})
        .then((res) => {
          if (res.data.status == "n") {
            this.$toast(res.data.error);
            return;
          }
          this.$toast('反馈成功');
          this.orderInfo.has_report = 1;
          this.reportIndex = -1;
          this.reportReason = "";
          this.fileList = [];
          this.hideModel();
        })
    },

    // 上传报告
    toCommitBaogao(item) {
      let order_id = item.order_id;
      let type = item.task_require;
      this.$router.push({
        name: 'commit',
        query: {
          id: order_id,
          type,
          fromPage: 'orderList',
          current: this.currentNav
        }
      })
    },

    toGoodsInvite() {

    },

    toOrderDetail(item) {
      this.$router.push({
        name: 'orderdetail',
        query: {
          id: item.order_id
        }
      })
    },

    async toChat(item) {
      let seller_id = item.seller_id;
      let order_id = item.order_id;
      let nick = item.seller_nick_name;
      //订单上报
      await this.recordOrder(seller_id, order_id);
      let account = 'seller_' + seller_id;
      // 更新会话对象
      this.$store.dispatch({
        type: 'CurrentChatTo_Change',
        payload: account
      });
      //获取当前点击的会话对象的聊天信息
      this.getMessages(account);
      this.$router.push({
        name: 'chat',
        query:{
          chatTo: account,
          nick: nick
        }
      });
    },
    //订单上报
    recordOrder(seller_id, order_id) {
      return new Promise(resolve => {
        let initparams = {
          order_id,
          seller_id,
          access_token: localStorage.getItem('access_token')
        };
        let params = this.$addParams(initparams);
        this.$axios.post(
          process.env.VUE_APP_URL + "/mobi/quickmessage/recordorder",
          this.$qs.stringify(params), {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        ).then((res) => {
          if (res.data.status == 'y') {
            console.log("订单上报成功");
            resolve()
          } else {
            this.$toast(res.data.error);
          }
        })
      })
    },
    getMessages(id) {
      if (!this.$store.state.nim) {
        return
      }
      let self = this;
      this.$store.state.nim.getHistoryMsgs({
        scene: "p2p",
        to: id,
        limit: 10,
        asc: true,
        done: (err, data) => {
          if (err) {
            console.log(err);
            return
          }
          console.log(data);
          //更新聊天信息列表
          self.$store.dispatch('SetCurrentChatMessage',data.msgs)
        }
      })
    },

    toCourse(){
      let url = "https://img.jimeimadou.com/user/img/auth_course/note_jc_xhs.png";
      this.showXhsUrl = true;
      this.xhs_url = url;
    },

    fetchExpressList() {
      let initparams = {
        access_token: localStorage.getItem("access_token"),
      };
      let params = this.sign_module.signParams(initparams);
      params["_platform"] = "mobi";
      params["_version"] = "1.0.0";
      axios.post(
        process.env.VUE_APP_URL + "/mobi/order/wuliucompany",
        qs.stringify(params), {headers: {"Content-Type": "application/x-www-form-urlencoded",},})
        .then((res) => {
          if (res.data.status == "n") {
            this.$toast(res.data.error);
            return;
          }
          this.expressList = JSON.parse(res.data.data);
        })
    }

  },
  activated() {
    this.fetchExpressList();
  },
  beforeRouteLeave(e,r,next){
    this.$refs.iscroll.scrollToTop();
    next();
  },
  beforeRouteEnter(e, r, next) {
    next(vm => {
      if (r.name === 'commit' && vm.$route.query.page === 'list') {
        if (vm.$route.query.type) {
          vm.currentNav = vm.$route.query.type;
        }
        vm.list_refresh();
      } else if (r.name !== 'orderdetail' && r.name !== 'commit' && r.name !== 'chat') {
        if (vm.$route.query.type) {
          vm.currentNav = vm.$route.query.type;
        }
        vm.list_refresh();
      }
    });
  }
}